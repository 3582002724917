import React, { useState } from "react";
import checkMarkIcon from "../../assets/images/checkmark_check_tick-green_bg.svg";
import { livingAction } from "../../actions/living.action";
import { DashboardAction } from "../../actions/index";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { SpinnerContext } from "../../components/spinner/spinner.component";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const ClaimFacility = ({ facilityId, Facilityname, dashboard, flag }) => {
  const navigate = useHistory();
  const [modalVisible, setModalVisible] = useState(false);
  const dispatch = useDispatch();
  const contextType = React.useContext(SpinnerContext);

  const handleClaimFacilityClick = () => {
    dispatch(livingAction.updateClaimFacility(facilityId?.facilityId));
    setModalVisible(true);
  };

  const closeModal = () => {
    contextType.setClaimedFacility(false);
    setModalVisible(false);
    navigate.push("/profile");
  };

  return (
    <>
      {contextType?.claimedFacility === true ? (
        <div className="claim-facility-btn" style={{ float: "right" }}>
          <button
            type="button"
            className="btn btn-primary radius-20 mt-20 justify-content-center"
            onClick={handleClaimFacilityClick}
          >
              Unclaimed Facility
          </button>
        </div>
      ) : dashboard === "yes" && flag ? (
        <>
          <div className="claim-facility-btn" style={{ float: "right" }}>
            <Link
              to="/dashboard"
              onClick={() =>
                dispatch(
                  DashboardAction.setDataforRedirection({
                    name: Facilityname,

                    url: `${navigate?.location?.pathname}`,
                  })
                )
              }
              className="btn btn-primary radius-20 mt-20 justify-content-center"
            >
              View Dashboard
            </Link>
          </div>
        </>
      ) : (
        ""
      )}

      {modalVisible && (
        <div
          className="modal upgrade-plan cancel-subscription-modal updated-successfully-modal already-updated-modal fade show"
          id="exampleModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="upgrade-plan-content">
                <img src={checkMarkIcon} className="img-fluid" alt="" />
                <span className="loading-msg d-block">
                  You have successfully claimed this facility center. Complete
                  your subscription and get listed in home page
                </span>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="modal-btn confirm-btn"
                  onClick={closeModal}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ClaimFacility;
