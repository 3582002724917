import React, { useState } from "react";
const Residentialcontent = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleAccordion = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index); // Close if it's already expanded, else open
  };

  const faqData = [
    {
      question: "What are residential treatment centers?",
      answer: (
        <>
          <p className="mb-2">
            Residential treatment centers are facilities that provide structured
            and supportive environments for individuals struggling with mental
            health issues, substance use disorders, eating disorders, etc. These
            centers offer 24/7 care and support, helping individuals focus on
            their recovery in a safe and therapeutic setting.
          </p>
          <p>
            The program itself is typically called a residential treatment
            program. These programs include therapy, medical care, and other
            support services to aid in recovery.
          </p>
        </>
      ),
    },
    {
      question:
        "Whats the difference between residential drug abuse treatment programs and those for mental health or other behavioral disorders?",
      answer: (
        <>
          <p className="mb-2">
            Residential programs for drug abuse focus on helping individuals
            overcome substance use disorders. These programs often start with
            detox, followed by therapy and support to address addiction and any
            co-occurring mental health issues.
          </p>
          <p className="mb-2">
            Residential programs for mental health focus on treating specific
            psychological or behavioral issues. These programs provide intensive
            therapy, medical care, and support tailored to the individual need.
          </p>
          <p>
            While both types of programs offer comprehensive care, the main
            difference is in the primary focus of the treatment.
          </p>
        </>
      ),
    },
    {
      question:
        "Do residential substance abuse treatment programs always include detox?",
      answer: (
        <p>
          No, residential drug treatment programs do not always include detox.
          Detox is only necessary if the individual needs to safely withdraw
          from substances. Some people may have already completed detox before
          entering a residential treatment program.
        </p>
      ),
    },
    {
      question: "What is sober living?",
      answer: (
        <p>
          Sober living refers to group homes or residences where individuals in
          recovery live together in a supportive environment. These homes
          provide a safe and structured environment for people who have
          completed residential treatment programs but still need support. They
          support people as they transition back to everyday life. During their
          stay, residents follow house rules, participate in chores, and often
          attend therapy or support group meetings.
        </p>
      ),
    },
    {
      question:
        "How do I know if a residential treatment program is right for substance abuse?",
      answer: (
        <p>
          Residential treatment is ideal for individuals who require a
          structured environment and 24/7 support to overcome addiction. It is
          especially recommended for those with severe substance use disorders
          or those who have relapsed after outpatient treatment.
        </p>
      ),
    },
    {
      question:
        "What is dual diagnosis treatment? Can I receive dual diagnosis treatment in a residential program?",
      answer: (
        <p>
          Dual diagnosis treatment addresses both mental health disorders and
          substance abuse simultaneously. Ensuring both conditions are treated
          in an integrated manner increases the likelihood of better outcomes.
        </p>
      ),
    },
    {
      question:
        "What age range is typically accepted in adolescent residential treatment programs?",
      answer: (
        <>
          <p className="mb-2">
            Adolescent programs usually cater to individuals between the ages of
            12 and 18. However, some programs may extend services to young
            adults (such as between 18 and 25).
          </p>
          <p>
            These programs provide educational support to address the unique
            needs of adolescents.
          </p>
        </>
      ),
    },
    {
      question: "Why might someone choose a gender-specific treatment program?",
      answer: (
        <>
          <p className="mb-2">
            Gender-specific residential treatment programs provide a safe
            environment tailored to the unique experiences of men or women.
            Sometimes, people feel safer opening up in gender-specific programs,
            which can enhance the effectiveness of treatment.
          </p>
          <p>
            While the therapies are similar, gender-specific programs
            incorporate gender-sensitive approaches to address issues like
            trauma, relationships, and self-esteem.
          </p>
        </>
      ),
    },
    {
      question:
        "What makes a faith-based residential treatment program different from other programs?",
      answer: (
        <p>
          Faith-based programs incorporate spiritual principles and practices
          into the treatment process. They aim to provide support for
          individuals who wish to align their recovery with their religious
          beliefs. These programs typically integrate religious counseling,
          scripture study, and community worship into daily activities.
        </p>
      ),
    },
    {
      question:
        "What's the difference between faith-based treatment and 12-step based treatment?",
      answer: (
        <>
          <p className="mb-2">
            While 12-step programs do include a spiritual element, they are not
            tied to a specific religion or set of religious beliefs. The concept
            of a higher power is intentionally broad. This allows individuals to
            define it in a way that is meaningful to them.
          </p>
          <p>
            12-step-based treatment facilities are generally inclusive and
            suitable for people of any religious background. These programs may
            also be suitable for those who prefer a non-religious approach.
          </p>
        </>
      ),
    },
  ];

  return (
    <div className="container">
      <div className="faq-content">
        <div className="faq-content-block">
          <h2 className="faqs">
            Residential Rehabilitation Treatment Programs FAQs
          </h2>
        </div>

        {faqData.map((faq, index) => (
          <div key={index} className="faq-content-block accordion-block">
            <div
              className={`accordion-header ${
                expandedIndex === index ? "expanded" : ""
              }`}
              onClick={() => toggleAccordion(index)}
            >
              <h3>{faq.question}</h3>
              <span className="icon"></span>
            </div>
            {expandedIndex === index && (
              <div className="accordion-content">
                <div className="inner-content">{faq.answer}</div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Residentialcontent;
