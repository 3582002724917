import React, { useState } from "react";
const Detoxcontent = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleAccordion = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index); // Close if it's already expanded, else open
  };
  const faqData = [
    {
      question: "How Long Does a Medical Detox Program Take?",
      answer: (
        <div className="accordion-content">
          <div className="inner-content">
            <p>
              Detox programs can vary from patient to patient due to a variety
              of factors. This could include the substance the patient uses,
              their level of dependency, and other health factors. Detox
              addiction treatment can take anywhere from a few days to a few
              weeks or more, on average, though longer stays are not unheard of.
            </p>
          </div>
        </div>
      ),
    },
    {
      question: "What Kinds of Detox Addiction Treatments Are Available?",
      answer: (
        <div className="accordion-content">
          <div className="inner-content">
            <p className="mb-2">
              Each center in our network offers different approaches to detox
              drug treatment, though many of the programs overlap. Approaches
              may include:
            </p>
            <ul className="list-style pl-4 ml-3 mb-2">
              <li className="mb-1">
                Inpatient programs, which are also known as residential
                programs,
              </li>
              <li className="mb-1">Outpatient services,</li>
              <li className="mb-1">Medically supervised detox,</li>
              <li className="mb-1">Cognitive and behavioral therapies,</li>
              <li className="mb-1">And more.</li>
            </ul>
            <p>
              Our centers also offer holistic treatments for patients, such as
              nutrition support, mind and body practice, and more. For more
              information about individual detox treatment offerings, please
              reach out to our detox and treatment centers listed above.
            </p>
          </div>
        </div>
      ),
    },

    {
      question: "Is detoxification painful?",
      answer: (
        <div className="accordion-content">
          <div className="inner-content">
            <p className="mb-2">
              Yes, a physiological detox can be painful. However, it is
              important to note that this pain is typically caused by the
              withdrawlwithdrawal the body experiences during detoxification.
              Our detox addiction treatment offerings are designed to be as
              pain-free as possible. As such, we have medical staff on-site to
              ensure our patients are given the care they need while their
              bodies expel unwanted toxins.
            </p>
          </div>
        </div>
      ),
    },

    {
      question: "What Happens After I Detox?",
      answer: (
        <div className="accordion-content">
          <div className="inner-content">
            <p className="mb-2">
              Following our medical detox program, a patient may take their next
              steps toward recovery. This could include therapy, counseling,
              support groups, and more. Each of these approaches works in
              support of the detox drug treatment as a means of preventing a
              backslide. This programming may be administered at the detox
              treatment center or elsewhere, depending on the patient’s needs
              and limitations.
            </p>
          </div>
        </div>
      ),
    },

    {
      question: "How Should I Choose My Detox Treatment Center?",
      answer: (
        <div className="accordion-content">
          <div className="inner-content">
            <p className="mb-2">
              There’s obviously no one right detox and treatment center for
              every patient. While some specialize in holistic healing, others
              are more medically focused. It also bears mentioning that
              different detox treatment centers offer different amenities, have
              different requirements for admittance, and have different costs
              (insured or uninsured) associated.
            </p>

            <p className="mb-2">
              Deciding which detox treatment center to admit oneself to is a big
              decision. We recommend involving friends, family, and other
              trusted people in your network in the decision. Together, you can
              make an informed decision.
            </p>
          </div>
        </div>
      ),
    },

    {
      question: "Will Insurance Cover My Detox Drug Treatment?",
      answer: (
        <div className="accordion-content">
          <div className="inner-content">
            <p className="mb-2">
              Some insurance providers will cover some or all of the cost of a
              detox treatment. However, this is not a hard and fast rule, as
              every insurance provider (and each of the many insurance policies
              they offer) can vary wildly. We recommend reaching out to your
              insurance provider to inquire about coverage before admitting
              yourself.
            </p>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="container">
      <div className="faq-content">
        <div className="faq-content-block">
          <h2 className="faqs">Detox Programs Frequently Asked Questions</h2>
          <p>
            If you are wondering about the kinds of treatments available at
            detox treatment centers, we can help shine a light. These are the
            top questions our patients and their families ask when researching a
            detox treatment center:
          </p>
        </div>

        {faqData.map((faq, index) => (
          <div key={index} className="faq-content-block accordion-block">
            <div
              className={`accordion-header ${
                expandedIndex === index ? "expanded" : ""
              }`}
              onClick={() => toggleAccordion(index)}
            >
              <h3>{faq.question}</h3>
              <span className="icon"></span>
            </div>
            {expandedIndex === index && faq.answer}
          </div>
        ))}
        {/* <div className="faq-content-block accordion-block">
          <div className="accordion-header expanded">
            <h3>How Long Does a Medical Detox Program Take?</h3>
            <span className="icon"></span>
          </div>
          <div className="accordion-content">
            <div className="inner-content">
              <p>
                Detox programs can vary from patient to patient due to a variety
                of factors. This could include the substance the patient uses,
                their level of dependency, and other health factors. Detox
                addiction treatment can take anywhere from a few days to a few
                weeks or more, on average, though longer stays are not unheard
                of.
              </p>
            </div>
          </div>
        </div>
        <div className="faq-content-block accordion-block">
          <div className="accordion-header">
            <h3>What Kinds of Detox Addiction Treatments Are Available?</h3>
            <span className="icon"></span>
          </div>
          <div className="accordion-content">
            <div className="inner-content">
              <p className="mb-2">
                Each center in our network offers different approaches to detox
                drug treatment, though many of the programs overlap. Approaches
                may include:
              </p>
              <ul className="list-style pl-4 ml-3 mb-2">
                <li className="mb-1">
                  Inpatient programs, which are also known as residential
                  programs,
                </li>
                <li className="mb-1">Outpatient services,</li>
                <li className="mb-1">Medically supervised detox,</li>
                <li className="mb-1">Cognitive and behavioral therapies,</li>
                <li className="mb-1">And more.</li>
              </ul>
              <p>
                Our centers also offer holistic treatments for patients, such as
                nutrition support, mind and body practice, and more. For more
                information about individual detox treatment offerings, please
                reach out to our detox and treatment centers listed above.
              </p>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Detoxcontent;
