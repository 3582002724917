import React, { useState, useEffect, useContext, useRef } from "react";
import backImgBlack from "../../assets/images/back.svg";
import GoogleMapComponent from "../../components/map/google-map.component";
import LocationSearchInput from "../../components/map/location-search-input.component";
import FileUploadComponent from "../../components/upload-photos/FileUpload";
import { generateQueryParams, httpClient } from "../../utils/http-client.util";
import { useSelector, useDispatch } from "react-redux";
import { SERVER_APIS } from "../../constants";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import animatedComponents from "react-select/animated";
import { useHistory, useParams } from "react-router-dom";
import { detoxAction, homeAction } from "../../actions/index";
import Subscription from "../../components/subscription/subscription.component";
import { SpinnerContext } from "../../components/spinner/spinner.component";

import {
  formatCamelCaseToReadabcodtiontreated,
  formatCamelCaseToReadableadditionaservices,
  formatCamelCaseToReadablemedication,
  formatCamelCaseToReadablespe,
  formatCamelCaseToReadabletesting,
  formatString,
  urlCopypageresrict,
  formatCamelCaseToReadabpersonalAmenities,
  formatCamelCaseToReadabcommonAmenities,
  languages,
  colourStyles,
  formatCamelCaseToReadablestaffAssociated,
  formatCamelCaseToReadableteamconsists,
  formatCamelCaseToReadableinsurance,
} from "../../constants/utils";
import { useCallback } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import facilityIcon from "../../assets/images/facilities.svg";
const ReactSelect = (props) => {
  const {
    handleRecommendedFacility,
    recommendedFacility,
    selectedRecommendedID,
    setSelectedRecommendedID,
    setIsMobileTabContentVisibleforstaffinsurance,
    insuranceodalviewmodeallstaeIsclear,
    isMobileTabContentVisibleforinsurance,
    toggleMobileTabContentforinsurance,
  } = props;


  const formatOptionLabelSelected = ({ label }) => (
    <div className="search-result">
      <ul className="search-result-list d-flex flex-column">
        <li className="d-flex align-items-center">
          <span>{label?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</span>
        </li>
      </ul>
    </div>
  );

  const formatOptionLabelDropdown = ({ label }) => (
    <div className="search-result">
      <ul className="search-result-list d-flex flex-column">
        <li className=" d-flex align-items-center">
          <img
            src={facilityIcon}
            alt="Facility"
            className="img-fluid mr-2"
          />
          <span>{label?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</span>
        </li>
      </ul>
    </div>
  );


  return (
    <div className="col-md-12">
   <div
        className="listing-sub-header form-mobile-tab test mt-4 mt-md-5 mb-4 pt-2 pt-md-0 pb-md-2"
        
        onClick={toggleMobileTabContentforinsurance}
      >
        <h4 className="main-title mb-0">Recommend Facilities</h4>
       
      </div>
      <div
        className={`form-mobile-tab-content ${
          isMobileTabContentVisibleforinsurance ? "show" : ""
        }`}
      >
        <div className="form-tab-content-header d-block d-md-none">
          <div className="header-actions mb-4 d-flex align-items-center justify-content-between">
            <div className="header-actions-left">
              <a role="button">
                <img
                  src={backImgBlack}
                  alt=""
                  onClick={() =>
                    setIsMobileTabContentVisibleforstaffinsurance(false)
                  }
                />
              </a>
            </div>
            <div className="header-actions-right d-flex align-items-center">
              <button
                type="button"
                className="btn clear-btn"
                onClick={() => {
                  insuranceodalviewmodeallstaeIsclear();
                  setSelectedRecommendedID([]);
                }}
              >
                CLEAR
              </button>
              <button
                className="btn apply-btn"
                onClick={() => {
                  setIsMobileTabContentVisibleforstaffinsurance(false);
                }}
                type="button"
              >
                APPLY
              </button>
            </div>
          </div>
          
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              
            
            <div className="form-group">
             <label>Do you Want To Recommend Facilities?{" "}</label> 
          </div>


               <p>
                These can be other programs that you directly work with or know
                they're doing good work and would like to highlight them.{" "}
              </p>
              <p>
                They will show up on your profile as Recommended facilities.
              </p>
              <h5 className="main-title mb-2">
              Listed Facility{" "}
            </h5>
              <div className="form-group">
                 <Select
                  value={selectedRecommendedID}
                  placeholder="Search by Location or Facility Name"
                  closeMenuOnSelect={false}
                  isMulti
                  options={recommendedFacility?.map((ele) => ({
                    label: `${ele?.name}, ${ele?.geocodingResult?.cityName }, ${ele?.geocodingResult?.stateLong}`,
                    value: ele?.id
                  }))}
                  onChange={(e) => handleRecommendedFacility(e)}
                  formatOptionLabel={(option) =>
                    selectedRecommendedID.find(item => item.value === option.value)
                      ? formatOptionLabelSelected(option)
                      : formatOptionLabelDropdown(option)
                  }

                  styles={ {option: (provided, state) => ({...provided, backgroundColor: state.isSelected ? 'red' : state.isFocused ? 'rgba(255, 95, 95, .0784313725490196)' : 'white',})}}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReactSelect;



