import React, { Component } from "react";
import { DatePickerCalendar } from "react-nice-dates";
import "react-nice-dates/build/style.css";
import { enGB } from "date-fns/locale";
import {  isSameDay } from "date-fns";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import ToastError from "../error/toast-error";
import { TimeCapsules } from "../../constants";

class TourCalendarComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      selectedDate: this.props.bookingDetails
        ? this.props.bookingDetails["startsAt"]
        : undefined,
    };
    this.getTimeSlots = this.getTimeSlots.bind(this);
  }

  modifiers = {
    // disabled: date => getDay(date) === 6, // Disables Saturdays
    highlight: (date) =>
      this.props.availableDates.some((availableDate) =>
        isSameDay(new Date(availableDate), date)
      ),
  };
  modifiersClassNames = {
    highlight: "-highlight-avialable-date",
  };

  render() {
    return (
      <div>
        <Modal.Header closeButton>
          <Modal.Title className="text-center"> BOOK A TOUR</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DatePickerCalendar
            minimumDate={new Date()}
            date={this.state.date}
            onDateChange={(date) => this.setState({ date })}
            locale={enGB}
            modifiers={this.modifiers}
            modifiersClassNames={this.modifiersClassNames}
          />
          <div>
            {this.getTimeSlots(this.state.date).length > 0 ? (
              <div className="time-slot-wrap">
                {this.getTimeSlots(this.state.date).map((date, index) => {
                  return (
                    <span
                      key={index}
                      className={`time-slot ${
                        this.state.selectedDate === date ? "active" : ""
                      }`}
                      onClick={() =>
                        date !== this.state.selectedDate &&
                        this.setState({ selectedDate: date })
                      }
                    >
                      {this.getTimeString(date)}
                    </span>
                  );
                })}
              </div>
            ) : (
              <div>
                <div className="no-tour-error">
                  <span>No available tours, </span>
                  <span>please choose another day. </span>
                </div>
              </div>
            )}
          </div>
          {/* <div className="d-flex justify-content-center action-btn"></div> */}
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <ToastError {...this.props} />
          <Button
            className="btn btn-primary radius-20 center mr-3"
            size="lg"
            disabled={
              this.state.selectedDate ===
              this.props.bookingDetails?.["startsAt"]
            }
            onClick={() => this.props.onSelectTimeSlot(this.state.selectedDate)}
          >
            Submit
          </Button>
        </Modal.Footer>
      </div>
    );
  }
  getKeyByValue(object, value) {
    for (let prop in object) {
      if (object.hasOwnProperty(prop)) {
        if (object[prop] == value) return prop;
      }
    }
  }

  getTimeString(date) {
    const parseDate = new Date(date);
    let time = date.split("T")[1].slice(0, 5);
    let timeWithMeridian = this.getKeyByValue(TimeCapsules, time);
    return timeWithMeridian;
  }

  getTimeSlots(date) {
    return this.props.availableDates.filter((availableDate) =>
      isSameDay(new Date(availableDate), date)
    );
  }
}

export default TourCalendarComponent;
