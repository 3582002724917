import gambling from '../../../assets/images/Gambling.svg';
import gamblingActive from '../../../assets/images/GamblingActive.svg';
import eatingDisorders from '../../../assets/images/Eating Disorders.svg';
import eatingDisordersActive from '../../../assets/images/Eating DisordersActive.svg';
import genderSpecific from '../../../assets/images/gender-specific.svg';
import genderSpecificActive from '../../../assets/images/gender-specific-active.svg';
import dualDiagnosis from '../../../assets/images/dual-diagnosis.svg';
import dualDiagnosisActive from '../../../assets/images/dual-diagnosis-active.svg';
import faithBased from '../../../assets/images/Faith Based.svg';
import faithBasedActive from '../../../assets/images/Faith Based Active.svg';
import adolescentPrograms from '../../../assets/images/adolescent-programs.svg';
import adolescentProgramsActive from '../../../assets/images/adolescent-programs-active.svg';
import medicaid from '../../../assets/images/Medicaid.svg';
import medicaidActive from '../../../assets/images/MedicaidActive.svg';
import veterans from '../../../assets/images/Veterans.svg';
import veteransActive from '../../../assets/images/VeteransActive.svg';
import stepBased from '../../../assets/images/12 Step based.svg';
import StepBasedActive from '../../../assets/images/12 Step based Active.svg';
import sexAddiction from "../../../assets/images/sex Addiction.svg";
import sexAddictionActive from "../../../assets/images/sex AddictionActive.svg";
import substanceAbuse from "../../../assets/images/substance-abuse.svg";
import substanceAbuseActive from "../../../assets/images/substance-abuse-active.svg";
import mentalHealth from "../../../assets/images/mental-health.svg";
import mentalhealthActive from "../../../assets/images/mental-health-active.svg";



export const FilteredOptions = [
    { img: substanceAbuse, altTag:'Filter for Substance abuse', activeImg: substanceAbuseActive, label: 'Substance Abuse', value: "substanceUseDisorder",key:"specialized" },
    { img: mentalHealth,  altTag:'Filter for Mental Health',  activeImg: mentalhealthActive, label: 'Mental Health', value: "mentalHealth",key:"specialized" },
    { img: dualDiagnosis,  altTag:'Filter for dual diagnosis', activeImg: dualDiagnosisActive, label: 'Dual Diagnosis', value: "dualDiagnosis",key:"specialized" },
    { img: adolescentPrograms, altTag:'Filter for adolescent programs',  activeImg: adolescentProgramsActive, label: 'Adolescent Programs', value: "adolescents",key:"specialized" },
    { img: genderSpecific, altTag:'Filter for gender specific',    activeImg: genderSpecificActive, label: 'Gender Specific', value: "genderSpecific", key:"isGenderSpecific" },
    { img: stepBased,   altTag:'Filter for 12-step based',  activeImg: StepBasedActive, label: '12 Step Based', value: "twelveStepFacilitation",key:"treatmentApproaches" },
    { img: faithBased,  altTag:'Filter for faith based', activeImg: faithBasedActive, label: 'Faith Based', value: "faithBasedProgram",key:"treatmentApproaches" },
    { img: eatingDisorders,   altTag:'Filter for eating disorders', activeImg: eatingDisordersActive, label: 'Eating Disorder', value: "eatingDisorders",key:"conditionTreated" },
    { img: sexAddiction,  altTag:'Filter for sex addiction', activeImg: sexAddictionActive, label: 'Sex Addiction', value: "sexAddiction",key:"conditionTreated" },
    { img: gambling,  altTag:'Filter for gambling', activeImg: gamblingActive, label: 'Gambling Addiction', value: "gambling",key:"conditionTreated" },
    { img: veterans, altTag:'Filter for veterans', activeImg: veteransActive, label: 'Veterans', value: "veterans",key:"specialized" },
    { img: medicaid,   altTag:'Filter for medicaid', activeImg: medicaidActive, label: 'Medicaid', value: "medicaid",key:"insurance" },
    
];