import React, { Component } from "react";
import { connect } from "react-redux";
import * as auth from "../../actions";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { mapErrorToField } from "../../utils";
import { Button, Toast } from "react-bootstrap";
import { SpinnerContext } from "../../components/spinner/spinner.component";
import cancleImg from "../../assets/images/cancle.svg";

const MyContext = React.createContext();
class Login extends Component {
  static contextType = SpinnerContext;
  loginSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is Required"),
    password: Yup.string().required("Password is required"),
  });

  constructor(props) {
    super(props);
    this.onLogin = this.onLogin.bind(this);
    this.state = {
      forgotShow: false,
      showError: false,

      signInModalShow: false,
    };
  }

  showForm = ({
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    errors,
    touched,
  }) => {
    let showForgot = () => {
      this.setState({ forgotShow: true });
      this.loginSchema.fields.password = Yup.string().notRequired();
    };

    return (
      <div className="login-wrapper">
        <div className="modal-header">
          <div className="login-modal-header" onClick={this.props.onHide}>
            <img
              src={cancleImg}
              className="img-fluid"
              alt=""
              style={{ cursor: "pointer" }}
            />
          </div>
          {this.state.forgotShow ? (
            <>
              <h5 className="modal-title" id="">
                Forgot password?
              </h5>
              <p>Please enter your email to receive password</p>
            </>
          ) : (
            <>
              <h5 className="modal-title" id="">
                Sign In
              </h5>
              <p className="mb-0">Sign in to your account</p>
            </>
          )}
        </div>
        <div className="modal-body p-0">
          <Toast
            onClose={() => this.showError(false)}
            show={this.state.showError}
            delay={3000}
            autohide
            className="list-group-item-warning"
          >
            <Toast.Body>
              <ul>
                {this.props.error &&
                  this.props.error.data.map(function (item) {
                    return <li key={item}>{item}</li>;
                  })}
              </ul>
            </Toast.Body>
          </Toast>
          <Form onSubmit={handleSubmit}>
            <div className="authentication">
              <div className="form-group mb-0 pb-lg-1">
                <Field
                  type="email"
                  name="email"
                  className="form-control mt-0 mb-2"
                  onChange={handleChange}
                  value={values.email}
                  placeholder="Email"
                  required
                />
                {/* {touched.email && errors.email && <ErrorMessage component="div" className="error-message" name="email" />} */}
              </div>
              {!this.state.forgotShow && (
                <div>
                  <div className="form-group mb-0 pb-lg-1">
                    <Field
                      type="password"
                      name="password"
                      onChange={handleChange}
                      value={values.password}
                      placeholder="Password"
                      className="form-control mt-0 mb-2"
                      required
                    />
                    {/* {touched.password && errors.password && <ErrorMessage component="div" className="error-message" name="password" />} */}
                  </div>
                  <div className="forgot-pass">
                    <a href="#" role="button" onClick={showForgot}>
                      Forgot password?&nbsp;
                    </a>
                  </div>
                </div>
              )}
              <div className="action-btn my-4 py-2">
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  className="btn btn-primary"
                  size="lg"
                  block
                >
                  {!this.state.forgotShow ? " Sign In" : " Send Email"}
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        <div>
          <Formik
            initialValues={{ email: "", password: "", general: "" }}
            onSubmit={this.onLogin}
            validationSchema={this.loginSchema}
          >
            {(props) => this.showForm(props)}
          </Formik>
        </div>
        <div className="extra-link">
          Not registered yet?&nbsp;
          <a role="button" onClick={this.props.onRegistration}>
            {" "}
            Sign Up
          </a>
        </div>
      </div>
    );
  }

  onLogin(values, { props, setErrors, setSubmitting }) {
    const submissions = [];
    let message = "Signing in";
    if (this.state.forgotShow) {
      message = "Sending an email";
      submissions.push(this.props.forgotPassword(values.email));
    } else {
      submissions.push(this.props.login(values.email, values.password));
    }
    this.context.showLoader(message);
    Promise.all(submissions)
      .then(() =>
        setTimeout(this.props.registerDevice, this.props.sessionLogout(), 10)
      )
      .catch(() => {
        this.showError(true);
        setErrors(mapErrorToField(this.props.error));
        setSubmitting(false);
      })
      .finally(() => {
        this.context.hideLoader();
      });
  }

  showError = (value) => this.setState({ showError: value });
}

export default connect(
  ({ auth }) => ({ error: auth.error }),
  auth.authAction
)(Login);
