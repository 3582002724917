import React, { useState } from "react";

const Iopcontent = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleAccordion = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index); // Close if it's already expanded, else open
  };

  const faqData = [
    {
      question: "What is an Intensive Outpatient Program (IOP)?",
      answer: (
        <>
          <p>
            An Intensive Outpatient Program (IOP) offers intensive therapy and
            support while allowing individuals to continue living at home. IOP
            programs include individual and group therapy, and educational
            sessions to help individuals manage their recovery while maintaining
            their daily responsibilities. Generally, people in this program will
            meet with professionals a few times a week.
          </p>
        </>
      ),
    },
    {
      question: "How is PHP and IOP different?",
      answer: (
        <>
          <p className="mb-2">
            Partial Hospitalization Programs (PHP) provide more intensive care
            and support. They typically involve daily treatment for several
            hours each day. Individuals in PHPs go home at night but spend every
            day at the treatment facility.
          </p>
          <p>
            IOPs, on the other hand, require fewer hours each week. IOPs allow
            individuals to maintain more of their regular daily activities while
            still receiving treatment.
          </p>
        </>
      ),
    },
    {
      question:
        "Can I continue working or attending school while participating in an IOP?",
      answer: (
        <>
          <p>
            Yes. One of the main benefits is that IOPs allow individuals to
            continue with their daily activities, such as work or school, while
            receiving treatment. While this is a benefit, it's important to
            ensure that IOP treatment programs are the best choice for you.
          </p>
        </>
      ),
    },
    {
      question:
        "How do I know if I need an IOP instead of traditional outpatient therapy?",
      answer: (
        <>
          <p className="mb-2">
            If you have tried other methods such as medication, outpatient
            therapy, counselling, etc. but need additional support on your road
            to recovery. IOPs can provide the structure and support you need.
            Another good way to gauge the need is if you feel like you need
            therapy more than once a week, but not 24/7 care, IOP could be the
            right fit for you.
          </p>
          <p>
            Note that some may choose IOP programs after participating in
            inpatient treatment to help them continue their recovery.
          </p>
        </>
      ),
    },
    {
      question: "What conditions are treated in an IOP Program?",
      answer: (
        <>
          <p>
            IOPs commonly treat conditions such as depression, anxiety,
            substance use disorders, eating disorders, and other mental health
            issues.
          </p>
        </>
      ),
    },
    {
      question: "Who is a good candidate for IOP?",
      answer: (
        <>
          <p className="mb-2">
            IOP services are suitable for individuals who have completed
            residential treatment and need ongoing support. They're also helpful
            for those whose conditions are stable enough to not require 24/7
            care.
          </p>
          <p>
            It's also a good option for those who need more structure and
            support than traditional outpatient therapy.
          </p>
        </>
      ),
    },
    {
      question: "How long does an intensive outpatient treatment program last?",
      answer: (
        <>
          <p>
            The length of IOP varies depending on individual needs. Most
            programs run for 8-12 weeks, with sessions held 3-5 days a week. The
            duration of each session can range from 3 to 5 hours.
          </p>
        </>
      ),
    },
    {
      question: "How do I choose the right IOP program for me?",
      answer: (
        <>
          <p className="mb-2">
            Choosing the right intensive outpatient program is a personal
            choice, but there are a few factors you might want to consider.
            First, the specialization of the program. There are specific
            programs based on whether you need support for addictions, mental
            health, an eating disorder, etc. You may also want to consider the
            specific treatment approaches used, as some approaches may work best
            for you. For example, some may seek out faith-based treatment
            options, while others may seek meditation, trauma counselling, CBT,
            or EMDR.
          </p>
          <p>
            Finally, location is important since you will likely travel to the
            program multiple times per week.
          </p>
        </>
      ),
    },
    {
      question: "Will insurance cover the cost of an IOP?",
      answer: (
        <p>
          Many insurance plans cover Intensive Outpatient Programs, but coverage
          can vary depending on your provider and plan. It's essential to check
          with your insurance company to understand what they will cover. Even
          if the IOP program is covered by your insurance, you may need to pay
          some out-of-pocket costs.
        </p>
      ),
    },
  ];

  return (
    <div className="container">
      <div className="faq-content">
        <div className="faq-content-block">
          <h2 className="faqs">Intensive Outpatient Program FAQs</h2>
        </div>
        {faqData.map((faq, index) => (
          <div key={index} className="faq-content-block accordion-block">
            <div
              className={`accordion-header ${
                expandedIndex === index ? "expanded" : ""
              }`}
              onClick={() => toggleAccordion(index)}
            >
              <h3>{faq.question}</h3>
              <span className="icon"></span>
            </div>
            {expandedIndex === index && (
              <div className="accordion-content">
                <div className="inner-content">{faq.answer}</div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Iopcontent;
