import React, { useEffect, useState } from 'react';
import { formatString } from "../../constants/utils";
import { formatedText } from '../../utils/spellingModification';

const MappedCheckBox = ({ data, identifier, onCheckboxChange, isViewMore, value }) => {

    return (
        <>
            {!isViewMore ? (<>
                {data?.splice(0, 8)?.map(
                    (key, index, array) => {
                        return (
                            <div className="custom-control custom-checkbox" key={index}>
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id={`identifier${key}`}
                                    name={`identifier.${key}`}
                                    checked={value?.includes(key)}
                                    onChange={() =>
                                        onCheckboxChange(key, identifier)
                                    }
                                />
                                <label
                                    className="custom-control-label"
                                    htmlFor={`identifier${key}`}
                                >
                                    {formatedText(key)}
                                </label>
                            </div>
                        );
                    }
                )}
            </>
            ) : (<>
                {data?.map(
                    (key, index, array) => {
                        return (
                            <div className="custom-control custom-checkbox" key={index}>
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id={`identifier${key}`}
                                    name={`indentifier.${key}`}
                                    checked={value?.includes(key)}
                                    onChange={() =>
                                        onCheckboxChange(key, identifier)
                                    }
                                />
                                <label
                                    className="custom-control-label"
                                    htmlFor={`identifier${key}`}
                                >
                                    {formatedText(key)}
                                </label>
                            </div>
                        );
                    }
                )}
            </>)}
        </>);
};

export default MappedCheckBox