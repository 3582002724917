import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Logoutsessionmodal from "../components/modal/Logoutsessionmodal";
import { authAction } from "../actions";
import { SpinnerContext } from "../components/spinner/spinner.component";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const SessionTimeout = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const sessionExpired = useSelector((state) => state.auth);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const { showLogin } = useContext(SpinnerContext);

  useEffect(() => {
    if (location?.search === "?popup=open") {
      checkLogin();
    }
    if (location?.search === "?open-modal=true") {
      chatreRedirection();
    }
  }, [location]);

  const checkLogin = async () => {
    await showLogin(true);
  };

  const chatreRedirection = async () => {
    showLogin(true);
  };

  const handleSessionTimeout = () => {
    if (sessionExpired?.user?.id) {
      dispatch(authAction.sessionLogout());
    }

    if (sessionExpired.sessionInformation.isTokenExpired) {
      dispatch(authAction.logout());
      setOpenConfirmationModal(true);

      const timer = setTimeout(() => {
        setOpenConfirmationModal(false);
        localStorage.clear();
        window.location.reload();
      }, 5000);

      return () => clearTimeout(timer);
    }
  };

  useEffect(() => {
    handleSessionTimeout();
  }, [location]);

  return (
    <>
      {children}
      <Logoutsessionmodal open={openConfirmationModal} />
    </>
  );
};

export default SessionTimeout;
