import { persistReducer } from "redux-persist";
import { STORAGE } from "../constants";
import storage from "redux-persist/lib/storage";
import { homeActionType } from "../actions/home.action";

const initialHomeState = {
  states: [],
  fetchingStates: false,
  livingByGender: undefined,
  fetchingLivingByGender: false,
  livingByRecommended: [],
  fetchingLivingByRecommended: false,
  subscribePlan: [],
  fetchingPlan: false,
  isShow: true,
  coordinate: {},
  searchCoordinate: {},
  searchField: "",
  fetchingFacility: false,
  facilityList: [],
  filterFacilityList: [],
  allFacilityList: [],
  searchFieldCoordinateName: "",
  fetchingDetails: false,
  fetchRecent: false,
  facilityDetails: null,
  facilityRecentDetails: [],
  verifiedBadge: null,
  recommendedDropDownList: [],
  param: "",
};

export const homeReducer = persistReducer(
  {
    storage,
    key: STORAGE.HOME_KEY,
    keyPrefix: STORAGE.PREFIX,
    whitelist: ["states", "livingByGender", "livingByRecommended"],
  },
  (state = initialHomeState, action) => {
    switch (action.type) {
      case homeActionType.Fetching_States:
        return { ...state, fetchingStates: true };

      case homeActionType.States_Fetched:
        const { states } = action.payload;
        return { ...state, fetchingStates: false, states };

      case homeActionType.Fetching_Living_By_Gender:
        return { ...state, fetchingLivingByGender: true };

      case homeActionType.Living_By_Gender_Fetched:
        const { livingByGender } = action.payload;
        return { ...state, fetchingLivingByGender: false, livingByGender };

      case homeActionType.Fetching_Living_By_Recommended:
        return { ...state, fetchingLivingByRecommended: true };

      case homeActionType.Fetching_PlanState:
        return { ...state, fetchingPlan: true };

      case homeActionType.PlanState_Fetched:
        const { subscribePlan } = action.payload;
        return { ...state, fetchingPlan: false, subscribePlan };

      case homeActionType.Living_By_Recommended_Fetched:
        const { livingByRecommended } = action.payload;
        return {
          ...state,
          fetchingLivingByRecommended: false,
          livingByRecommended,
        };

      case homeActionType.Fetching_Facility:
        return { ...state, fetchingFacility: true };
      case homeActionType.Facility_Fetched:
        return {
          ...state,
          fetchingFacility: false,
          facilityList: action.payload,
        };
      case homeActionType.Facility_Fetched_Filter:
        return {
          ...state,
          fetchingFacility: false,
          filterFacilityList: action.payload,
        };
      case homeActionType.Facility_Fetched_All:
        return {
          ...state,
          fetchingFacility: false,
          allFacilityList: action.payload,
        };
      case homeActionType.Fetched_All_Facility:
        return {
          ...state,
          fetchingFacility: false,
          recommendedDropDownList: action.payload,
        };
      case homeActionType.Fetching_Facility_ById:
        return { ...state, fetchingDetails: true };
      case homeActionType.Fetched_Facility_ById:
        return {
          ...state,
          fetchingDetails: false,
          facilityDetails: action.payload,
        };

      case homeActionType.Fetching_Facility_RecentView:
        return { ...state, fetchRecent: true };
      case homeActionType.Fetched_Facility_RecentView:
        return {
          ...state,
          fetchRecent: false,
          facilityRecentDetails: action.payload,
        };

      case homeActionType.Fetched_Verified:
        return { ...state, verifiedBadge: action.payload };

      case homeActionType.ModelState_Fetch:
        return {
          ...state,
          isShow: action.payload,
        };

      case homeActionType.Set_Coordinate:
        return {
          ...state,
          coordinate: action.payload,
        };

      case homeActionType.Search_Coordinate:
        return {
          ...state,
          searchCoordinate: action.payload,
        };
      case homeActionType.Search_InputField_Coordinate:
        return {
          ...state,
          searchFieldCoordinateName: action.payload,
        };

      case homeActionType.Search_InputField:
        return {
          ...state,
          searchField: action.payload,
        };
      case homeActionType.Set_Param:
        return {
          ...state,
          param: action.payload,
        };
      default:
        return state;
    }
  }
);
