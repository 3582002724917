import React from "react";
import slickPrevArrow from "../../assets/images/arrow_left.svg";

const SliderPrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className={`slick-arrow prev-arrow`} onClick={onClick}>
      <img src={slickPrevArrow} className="img-fluid" alt="" />
    </div>
  );
};

export default SliderPrevArrow;
