import { httpClient } from "../utils";
import { SERVER_APIS } from "../constants";

export const detoxActionType = {
  Detox_Adding: "[Adding_Detox] Action",
  Detox_Add: "[Add_Detox] Action",
  Detox_Editing: "[Editing_Detox] Action",
  Detox_Edited: "[Edited_Detox] Action",
  fetch_Detox_ById_ActionType: "[GetById_Detox] Action",
  fetch_Detox_ById_Success: "[GetById_Detox_Sucess] Action",
  fetch_Detox_ById_Failure: "[GetById_Detox_Failure] Action",

  SubscriptionPlan_Fetching: "[ Fetching_DetoxPlan] Action",
  SubscriptionPlan_Fetched: "[ Fetched_DetoxPlan] Action",
  DetoxBasicPlan_Set: "[Set_DetoxBasicPlan] Action",
  DetoxProPlan_Set: "[Set_DetoxProPlan] Action",
  DetoxFacilityType_Set: "[Set_DetoxFacilityType] Action",
  SaveBookmarkFacility_Addeding: "[SaveBookmarkFacility_Addeding] Action",
  SaveBookmarkFacility_Added: "[SaveBookmarkFacility_Added] Action",
  Detox_Sub: "[sub_Detox] Action",

  Fetch_Subscription_Message: "[Fetch_Subscription_Message] Action",
  Fetch_Subscription_Message_Success:
    "[Fetch_Subscription_Message_Success] Action",
  Fetch_Subscription_Message_Failed:
    "[Fetch_Subscription_Message_Failed] Action",
};

const onAddDetox = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: detoxActionType.Detox_Adding });

      const res = await httpClient.post(SERVER_APIS.Detox, data);

      dispatch({
        type: detoxActionType.Detox_Add,
        payload: { detox: res?.data },
      });

      return res;
    } catch (error) {
      console.error("Error occurred:", error);

      dispatch({
        type: detoxActionType.Detox_Add,
        payload: { detox: {} },
      });

      throw error;
    }
  };
};

const onBookmarkfacility = (facilityId) => {
  return (dispatch) => {
    dispatch({ type: detoxActionType.SaveBookmarkFacility_Addeding });
    return httpClient
      .put(`${SERVER_APIS.Facility_SAVE}/${facilityId}/favorite`)
      .then((response) => {
        dispatch({
          type: detoxActionType.SaveBookmarkFacility_Added,
          payload: { response },
        });
        return Promise.resolve();
      })
      .catch(() => Promise.reject());
  };
};

const fetchDetoxDataById = (id) => {
  return (dispatch) => {
    dispatch({ type: detoxActionType.fetch_Detox_ById_ActionType });
    return httpClient
      .get(`${SERVER_APIS.Detox}/${id}`)
      .then((res) => {
        dispatch({
          type: detoxActionType.fetch_Detox_ById_Success,
          payload: { detoxDetail: res.data },
        });
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        console.error("Error occurred:", error);
        dispatch({
          type: detoxActionType.fetch_Detox_ById_Failure,
          payload: { error },
        });
        return Promise.reject(error);
      });
  };
};

const onEditDetox = (id, data) => {
  return (dispatch) => {
    dispatch({ type: detoxActionType.Detox_Editing });
    return httpClient
      .patch(`${SERVER_APIS.Detox}/${id}`, data)
      .then((res) => {
        dispatch({
          type: detoxActionType.Detox_Edited,
          payload: res.data,
        });
        return Promise.resolve();
      })
      .catch(({ response: { data } }) => {
        dispatch({
          type: detoxActionType.Detox_Edited,
        });
        return Promise.reject(data);
      });
  };
};

// Detox Subscription
const onFetchDetoxPlan = () => {
  return (dispatch) => {
    dispatch({ type: detoxActionType.SubscriptionPlan_Fetching });
    return httpClient
      .get(`${SERVER_APIS.Detox_Plan}`)
      .then((response) => {
        dispatch({
          type: detoxActionType.SubscriptionPlan_Fetched,
          payload: response.data,
        });
        return Promise.resolve();
      })
      .catch(() => {
        dispatch({
          type: detoxActionType.SubscriptionPlan_Fetched,
          payload: [],
        });
        return Promise.reject();
      });
  };
};

const setDetoxBasicPlan = (data) => {
  return (dispatch) => {
    dispatch({
      type: detoxActionType.DetoxBasicPlan_Set,
      payload: { id: data?.id, price: data?.unit_amount },
    });
  };
};

const setDetoxProPlan = (data) => {
  return (dispatch) => {
    dispatch({
      type: detoxActionType.DetoxProPlan_Set,
      payload: { id: data?.id, price: data?.unit_amount },
    });
  };
};

const setDetoxFacilityType = (data) => {
  return (dispatch) => {
    dispatch({
      type: detoxActionType.DetoxFacilityType_Set,
      payload: {
        facilitytype: data?.facilityType,
        id: data?.id,
        name: data?.name,
      },
    });
  };
};

const isChangeTrue = (data) => {
  return (dispatch) => {
    dispatch({
      type: detoxActionType.Detox_Sub,
      payload: data,
    });
  };
};

const fetchMessageForSubscription = (data) => {
  return (dispatch) => {
    dispatch({ type: detoxActionType.Fetch_Subscription_Message });
    return httpClient
      .post(`${SERVER_APIS.Upgrad_Message_show}`, data)
      .then((response) => {
        dispatch({
          type: detoxActionType.Fetch_Subscription_Message_Success,
          payload: response?.data,
        });
        return Promise.resolve(response?.data);
      })
      .catch(() => {
        dispatch({
          type: detoxActionType.Fetch_Subscription_Message_Failed,
          payload: [],
        });
        return Promise.reject();
      });
  };
};

export const detoxAction = {
  addDetox: onAddDetox,
  getDetoxById: fetchDetoxDataById,
  editDetox: onEditDetox,
  getDetoxSubscriptionPlan: onFetchDetoxPlan,
  setDetoxProPlan,
  setDetoxBasicPlan,
  setDetoxFacilityType,
  isChangeTrue,
  SaveFacility: onBookmarkfacility,
  fetchMessageForSubscription: fetchMessageForSubscription,
};
