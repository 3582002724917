// utils.js
const formatString = (str) => {
  return str
    .split(/(?=[A-Z])/) // Split the string by capital letters
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first character of each word
    .join(" "); // Join the words back together with space
};
const formatCamelCaseToReadabcodtiontreated = (text) => {
  // Manually handle special cases with direct mappings
  const specialCases = {
    adhd: "ADHD",
    sexHarm: "Sex-Harm",
    emdr: "EMDR",
    coOccurringDisorders: "Co-Occurring Disorders",
    airConditionedRooms: "Air-Conditioned Rooms",
    armchairOrSofa: "Armchair/Sofa",
    kitchenette: "Kitchenette  (Sink, Microwave, Mini Fridge)",
    bathroomEssentials: "Bathroom Essentials (Soap,Shampoo and Towel)",
    walkInCloset: "Walk-In-Closet",
    thermostat: "Thermostat (Adjustable Within Room)",
    outdoorSpaceConnectedRoom: "Outdoor space (Connected Room)",
    tv: "TV",
    privateOrSharedRooms: "Private/Shared Rooms",
    mentalCouplesCounselling: "Mental/Couples Counseling",
    substanceUseDisorderCounselling: "Substance Use Disorder Counseling",
    traumaRelatedCounselling: "Trauma Related Counseling",
    individualCounselling: "Individual Counseling",
    groupCounselling: "Group Counseling",
    familyCounselling: "Family Counseling",
    mentalHealthCounsellingForCoOccurringDisorders:
      "Mental Health Counseling For Co-Occurring Disorders",
    clientsWithTrance: "Clients With Trauma",
    clientWithHIVOrAIDS: "Clients With HIV Or AIDS",
    mentalCouplesCounselling: "Mental/Couples Counseling",
    mentoringPeerSupport: "Mentoring/Peer Support",
    aftercareContinuingCare: "Aftercare/Continuing Care",
    assistanceWithObtainingSocialServices:
      "Assistance With Obtaining Social Services",
    adolescents: "Adolescent Programs",
    telemedicineTelehealthTherapy: "Telemedicine/Telehealth Therapy",
    pregnantPostpartumWomen: "Pregnant/Postpartum Women",
    twentyFourSevenCare: "24/7 Care",
  };

  // Check if the key is in the special cases
  if (specialCases[text]) {
    return specialCases[text];
  }

  // Convert camelCase to words with a space before capital letters
  let formattedText = text?.replace(/([A-Z])/g, " $1").trim();

  // Capitalize the first letter of each word
  formattedText = formattedText
    ?.split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return formattedText;
};

const formatCamelCaseToReadabpersonalAmenities = (text) => {
  // Manually handle special cases with direct mappings
  const specialCases = {
    airConditionedRooms: "Air-Conditioned Rooms",
    armchairOrSofa: "Armchair/Sofa",
    kitchenette: "Kitchenette  (Sink, Microwave, Mini Fridge)",
    bathroomEssentials: "Bathroom Essentials (Soap,Shampoo And Towel)",
    walkInCloset: "Walk-In-Closet",
    thermostat: "Thermostat (Adjustable Within Room)",
    outdoorSpaceConnectedRoom: "Outdoor Space (Connected Room)",
    tv: "TV",
    privateOrSharedRooms: "Private/ Shared Rooms",
  };

  // Check if the key is in the special cases
  if (specialCases[text]) {
    return specialCases[text];
  }

  let formattedText = text?.replace(/([A-Z])/g, " $1").trim();

  // Capitalize the first letter of each word
  formattedText = formattedText
    ?.split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return formattedText;
};
const formatCamelCaseToReadabcommonAmenities = (text) => {
  // Manually handle special cases with direct mappings
  const specialCases = {
    chefPreparedMeals: "Chef-Prepared Meals",
  };

  // Check if the key is in the special cases
  if (specialCases[text]) {
    return specialCases[text];
  }

  let formattedText = text?.replace(/([A-Z])/g, " $1").trim();

  // Capitalize the first letter of each word
  formattedText = formattedText
    ?.split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return formattedText;
};

const formatCamelCaseToReadablespe = (text) => {
  // Manually handle special cases with direct mappings
  const specialCases = {
    substanceUseDisorder: "Substance Use Disorder",
    mentalHealth: "Mental Health",
    dualDiagnosis: "Dual Diagnosis",
    relapsePrevention: "Relapse Prevention",
    groupTherapy: "Group Therapy",
    familyTherapy: "Family Therapy",
    youngAdults: "Young Adults",
    telemedicineTelehealthTherapy: "Telemedicine/Telehealth Therapy",
    mentalHealthCounsellingForCoOccurringDisorders:
      "Mental Health Counseling For Co-occurring Disorders",
    addictionRecoveryEducation: "Addiction Recovery Education",
    mentalCouplesCounselling: "Mental/Couples Counseling",
    twentyFourSevenCare: "24/7 Care",
    recoveryCoach: "Recovery Coach",
    mentoringPeerSupport: "Mentoring/Peer Support",
    assistanceWithObtainingSocialServices:
      "Assistance With Obtaining Social Services",
    aftercareContinuingCare: "Aftercare/Continuing Care",
    dischargePlanning: "Discharge Planning",
    naloxoneAndOverdoseEducation: "Naloxone And Overdose Education",
    outcomeFollowUpAfterDischarge: "Outcome Follow-up After Discharge",
    pregnantPostpartumWomen: "Pregnant/Postpartum Women",
    seniorsOrOlderAdults: "Seniors Or Older Adults",
    clientWithHIVOrAIDS: "Clients With HIV Or AIDS",
    clientsWithTrance: "Clients With Trauma",
    clientsWithSuicidalIdeations: "Clients With Suicidal Ideations",
    selfHelpGroups: "Self Help Groups",
    assistanceWithObtainingSocialServices:
      "Assistance With Obtaining Social Services",
    adolescents: "Adolescent Programs",
  };

  // Check if the key is in the special cases
  if (specialCases[text]) {
    return specialCases[text];
  }

  // Convert camelCase to words with a space before capital letters
  let formattedText = text.replace(/([A-Z][a-z]+)/g, " $1").trim();

  // Capitalize the first letter of the resulting string
  formattedText =
    formattedText.charAt(0).toUpperCase() + formattedText.slice(1);

  // Convert the second word to lowercase
  const words = formattedText.split(" ");
  if (words.length > 1) {
    words[1] = words[1].toLowerCase();
  }

  // Join the words back together
  formattedText = words.join(" ");

  // Handle common phrases that need adjustments
  formattedText = formattedText
    .replace(/ Co Occurring /g, " Co-Occurring ")
    .replace(/ Postpartum /g, "/Postpartum ");

  return formattedText;
};
const formatCamelCaseToReadablemedication = (text) => {
  // Manually handle special cases with direct mappings
  const specialCases = {
    acamprosateCampral: "Acamprosate (Campral)",
    buprenorphineWithNaloxone: "Buprenorphine With Naloxone",
    buprenorphineWithoutNaloxone: "Buprenorphine Without Naloxone",
    buprenorphineExtendedReleaseInjection:
      "Buprenorphine (Extended-Release, Injection)",
    naltrexoneOral: "Naltrexone (Oral)",
    naltrexoneExtendedReleaseInjection:
      "Naltrexone (Extended-Release, Injection)",
    medicationForMentalDisorders: "Medication For Mental Disorders",
    nonNicotineSmokingCessation: "Non-Nicotine Smoking/Tobacco Cessation",
  };

  // Check if the key is in the special cases
  if (specialCases[text]) {
    return specialCases[text];
  }

  // Convert camelCase to words with a space before capital letters
  let formattedText = text?.replace(/([A-Z])/g, " $1").trim();

  // Capitalize the first letter of each word
  formattedText = formattedText
    ?.split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return formattedText;
};
const formatCamelCaseToReadabletreatmentapproaches = (text) => {
  // Manually handle special cases with direct mappings
  const specialCases = {
    contingencyManagementMotivationalIncentives:
      "Contingency Management/Motivational Incentives",
    twelveStepFacilitation: "12 Step Facilitation",
    mentalCouplesCounselling: "Mental/Couples Counseling",
    emdr: "EMDR",
    gymDailyFitnessActivities: "Gym/Daily Fitness Activities",
    experientialTherapy: "Experiential Therapy",
    aaTherapy: "AA Therapy",
    traumaRelatedCounselling: "Trauma Related Counseling",
    individualCounselling: "Individual Counseling",
    groupCounselling: "Group Counseling",
    familyCounselling: "Family Counseling",
    mentalCouplesCounselling: "Mental Couples Counseling",
    substanceUseDisorderCounselling: "Substance Use Disorder Counseling",
  };

  // Check if the key is in the special cases
  if (specialCases[text]) {
    return specialCases[text];
  }

  let formattedText = text?.replace(/([A-Z])/g, " $1").trim();

  // Capitalize the first letter of each word
  formattedText = formattedText
    ?.split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return formattedText;
};
const formatCamelCaseToReadableassessmentPretreatment = (text) => {
  // Manually handle special cases with direct mappings
  const specialCases = {
    completeMedicalHistoryPhysicalExam:
      "Complete Medical History/Physical Exam",
  };

  // Check if the key is in the special cases
  if (specialCases[text]) {
    return specialCases[text];
  }
  let formattedText = text?.replace(/([A-Z])/g, " $1").trim();

  // Capitalize the first letter of each word
  formattedText = formattedText
    ?.split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return formattedText;
};
const formatCamelCaseToReadableteamconsists = (text) => {
  // Manually handle special cases with direct mappings
  const specialCases = {
    registeredDieticiansNutritionist: "Registered Dieticians/Nutritionist",
    counsellors: "Counselors",
  };

  // Check if the key is in the special cases
  if (specialCases[text]) {
    return specialCases[text];
  }

  let formattedText = text?.replace(/([A-Z])/g, " $1").trim();

  // Capitalize the first letter of each word
  formattedText = formattedText
    ?.split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return formattedText;
};

const formatCamelCaseToReadableadditionaservices = (text) => {
  // Manually handle special cases with direct mappings
  const specialCases = {
    registeredDieticiansNutritionist: "Registered Dieticians/Nutritionist",
    iop: "IOP",
    php: "PHP",
    op: "OP",
    residential24HourResidential: "Residential/24 Hour Residential",
    residential24HourResidentia: "Residential/24 Hour Residential",
    kitchenette: "Kitchenette  (Sink, Microwave, Mini Fridge)",
    bathroomEssentials: "Bathroom Essentials (Soap,Shampoo And Towel)",
    walkInCloset: "Walk-In-Closet",
    thermostat: "Thermostat (Adjustable Within Room)",
    outdoorSpaceConnectedRoom: "Outdoor Space (Connected Room)",
  };

  // Check if the key is in the special cases
  if (specialCases[text]) {
    return specialCases[text];
  }

  // Convert camelCase to words with a space before capital letters
  let formattedText = text?.replace(/([A-Z])/g, " $1").trim();

  // Capitalize the first letter of each word
  formattedText = formattedText
    ?.split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return formattedText;
};

const formatCamelCaseToReadableadditionaservicesIOP = (text) => {
  // Manually handle special cases with direct mappings
  const specialCases = {
    registeredDieticiansNutritionist: "Registered Dieticians/Nutritionist",
    php: "PHP (5 Hours Per Day)",
    op: "OP (1 Hour Per Day)",
    residential24HourResidential: "Residential/24 Hour Residential",
    residential24HourResidentia: "Residential/24 Hour Residential",
  };

  // Check if the key is in the special cases
  if (specialCases[text]) {
    return specialCases[text];
  }

  // Convert camelCase to words with a space before capital letters
  let formattedText = text?.replace(/([A-Z])/g, " $1").trim();

  // Capitalize the first letter of each word
  formattedText = formattedText
    ?.split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return formattedText;
};

export const formatCamelCaseToReadableadditionaservicesforPHP = (text) => {
  // Manually handle special cases with direct mappings
  const specialCases = {
    registeredDieticiansNutritionist: "Registered Dieticians/Nutritionist",
    iop: "IOP (3 Hours Per Day)",
    op: "OP (1 Hour Per Day)",
    residential24HourResidential: "Residential/24 Hour Residential",
    residential24HourResidentia: "Residential/24 Hour Residential",
  };

  // Check if the key is in the special cases
  if (specialCases[text]) {
    return specialCases[text];
  }

  let formattedText = text?.replace(/([A-Z])/g, " $1").trim();

  // Capitalize the first letter of each word
  formattedText = formattedText
    ?.split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return formattedText;
};

const formatCamelCaseToReadableadditionaservicesforesidentail = (text) => {
  // Manually handle special cases with direct mappings
  const specialCases = {
    registeredDieticiansNutritionist: "Registered Dieticians/Nutritionist",
    iop: "IOP",
    php: "PHP",
    op: "OP",
    residential24HourResidential: "Residential/24 Hour Residential",
    residential24HourResidentia: "Residential/24 hour Residential",
  };

  // Check if the key is in the special cases
  if (specialCases[text]) {
    return specialCases[text];
  }

  // Convert camelCase to words with a space before capital letters
  let formattedText = text?.replace(/([A-Z])/g, " $1").trim();

  // Capitalize the first letter of the resulting string and ensure the rest is lowercase
  formattedText =
    formattedText?.charAt(0)?.toUpperCase() +
    formattedText?.slice(1)?.toLowerCase();

  return formattedText;
};

export const formatCamelCaseToReadableadditionaservicesforesidentailResidential =
  (text) => {
    // Manually handle special cases with direct mappings
    const specialCases = {
      registeredDieticiansNutritionist: "Registered Dieticians/Nutritionist",
      iop: "IOP (3 Hours Per Day)",
      php: "PHP (5 Hours Per Day)",
      op: "OP (1 Hour Per Day)",
      residential24HourResidential: "Residential/24 Hour Residential",
      residential24HourResidentia: "Residential/24 Hour Residential",
    };

    // Check if the key is in the special cases
    if (specialCases[text]) {
      return specialCases[text];
    }

    let formattedText = text?.replace(/([A-Z])/g, " $1").trim();

    // Capitalize the first letter of each word
    formattedText = formattedText
      ?.split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    return formattedText;
  };
const formatCamelCaseToReadableinsurance = (text) => {
  // Manually handle special cases with direct mappings
  const specialCases = {
    federalMilitaryInsurance: "Federal Military Insurance (e.g., Tricare)",
    stateFinancedHealthInsurance:
      "State Financed Health Insurance Plan For Other Than Medicaid",
  };

  // Check if the key is in the special cases
  if (specialCases[text]) {
    return specialCases[text];
  }

  let formattedText = text?.replace(/([A-Z])/g, " $1").trim();

  // Capitalize the first letter of each word
  formattedText = formattedText
    ?.split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return formattedText;
};
const formatCamelCaseToReadablesubstutanceorder = (text) => {
  // Manually handle special cases with direct mappings
  const specialCases = {
    substanceusedisorder: "Substance Use Disorder",
    dualdiagnosis: "Dual Diagnosis",
    mentalhealth: "Mental Health",
    clientWithHIVOrAIDS: "Client With HIV Or AIDS",
    pregnantPostpartumWomen: "Pregnant/Postpartum Women",
    telemedicineTelehealthTherapy: "Telemedicine/Telehealth Therapy",
  };

  // Check if the key is in the special cases
  if (specialCases[text]) {
    return specialCases[text];
  }

  let formattedText = text?.replace(/([A-Z])/g, " $1").trim();

  // Capitalize the first letter of each word
  formattedText = formattedText
    ?.split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return formattedText;
};
const formatCamelCaseToReadabletesting = (text) => {
  // Manually handle special cases with direct mappings
  const specialCases = {
    drugOrAlcoholUrineScreening: "Drug Or Alcohol Urine Screening",
    drugAndOralFluidTesting: "Drug And Oral Fluid Testing",
    breathalyzerOrBloodAlcoholTesting: "Breathalyzer Or Blood Alcohol Testing",
    buprenorphineWithNaloxone: "Buprenorphine With Naloxone",
    buprenorphineWithoutNaloxone: "Buprenorphine Without Naloxone",
    buprenorphineExtendedReleaseInjection:
      "Buprenorphine (Extended-Release, Injection)",
    naltrexoneOral: "Naltrexone (Oral)",
    naltrexoneExtendedReleaseInjection:
      "Naltrexone (Extended-Release, injection)",
    medicationForMentalDisorders: "Medication Gor Mental Disorders",
    TBTesting: "TB Screening",
  };

  // Check if the key is in the special cases
  if (specialCases[text]) {
    return specialCases[text];
  }

  // Convert camelCase to words with a space before capital letters
  let formattedText = text.replace(/([A-Z][a-z]+)/g, " $1").trim();

  // Capitalize the first letter of the resulting string
  formattedText =
    formattedText.charAt(0).toUpperCase() + formattedText.slice(1);

  // Convert the second word to lowercase
  const words = formattedText.split(" ");
  if (words.length > 1) {
    words[1] = words[1].toLowerCase();
  }

  // Join the words back together
  formattedText = words.join(" ");

  return formattedText;
};
const formatTestKeyToReadableiopphp = (key) => {
  switch (key) {
    case "breathalyzerOrBloodAlcoholTesting":
      return "Breathalyzer Or Blood Alcohol Testing";
    case "HIVTesting":
      return "HIV Testing";
    case "drugOrAlcoholUrineScreening":
      return "Drug Or Alcohol Urine Screening";
    case "STDTesting":
      return "STD Testing";
    case "TBTesting":
      return "TB Testing";
    case "testingForHepatitisB":
      return "Testing For Hepatitis B (HBV)";
    case "testingForHepatitisC":
      return "Testing For Hepatitis C (HCV)";
    default:
      return key
        ?.replace(/([A-Z])/g, " $1") // Add space before each capital letter
        ?.trim() // Remove any leading or trailing whitespace
        ?.split(" ") // Split the string into words
        ?.map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        ) // Capitalize the first letter of each word
        ?.join(" "); // Join the words back together with a space
  }
};
const urlCopypageresrict = (text) => {
  // Regular expression to validate URLs
  const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
  return urlRegex.test(text);
};
const colourStyles = {
  menuList: (styles) => ({
    ...styles,
    background: "hsl(0, 0%, 100%)",
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    background: isFocused ? "" : isSelected ? "hsl(0, 0%, 80%)" : undefined,
    zIndex: 1,
  }),
  menu: (base) => ({
    ...base,
    zIndex: 100,
  }),
};
const languages = [
  { value: "english", label: "English" },
  { value: "spanish", label: "Spanish" },
  { value: "french", label: "French" },
  { value: "portuguese", label: "Portuguese" },
  { value: "german", label: "German" },
  { value: "italian", label: "Italian" },
  { value: "chinese", label: "Chinese" },
  { value: "japanese", label: "Japanese" },
  { value: "korean", label: "Korean" },
  { value: "arabic", label: "Arabic" },
  { value: "russian", label: "Russian" },
  { value: "dutch", label: "Dutch" },
  { value: "swedish", label: "Swedish" },
  { value: "norwegian", label: "Norwegian" },
  { value: "danish", label: "Danish" },
  { value: "finnish", label: "Finnish" },
  { value: "turkish", label: "Turkish" },
  { value: "greek", label: "Greek" },
  { value: "polish", label: "Polish" },
  { value: "hindi", label: "Hindi" },
  { value: "czech", label: "Czech" },
  { value: "hungarian", label: "Hungarian" },
  { value: "indonesian", label: "Indonesian" },
  { value: "malay", label: "Malay" },
  { value: "thai", label: "Thai" },
  { value: "vietnamese", label: "Vietnamese" },
  { value: "ukrainian", label: "Ukrainian" },
  { value: "romanian", label: "Romanian" },
  { value: "bulgarian", label: "Bulgarian" },
  { value: "croatian", label: "Croatian" },
  { value: "serbian", label: "Serbian" },
  { value: "slovak", label: "Slovak" },
  { value: "slovenian", label: "Slovenian" },
  { value: "estonian", label: "Estonian" },
  { value: "latvian", label: "Latvian" },
  { value: "lithuanian", label: "Lithuanian" },
  { value: "tagalog", label: "Tagalog" },
  { value: "maltese", label: "Maltese" },
  { value: "icelandic", label: "Icelandic" },
  { value: "welsh", label: "Welsh" },
  { value: "scots_gaelic", label: "Scots Gaelic" },
  { value: "basque", label: "Basque" },
  { value: "catalan", label: "Catalan" },
  { value: "galician", label: "Galician" },
  { value: "kurdish", label: "Kurdish" },
  { value: "pashto", label: "Pashto" },
  { value: "urdu", label: "Urdu" },
  { value: "punjabi", label: "Punjabi" },
  { value: "telugu", label: "Telugu" },
  { value: "tamil", label: "Tamil" },
  { value: "bengali", label: "Bengali" },
  { value: "marathi", label: "Marathi" },
  { value: "gujarati", label: "Gujarati" },
  { value: "kannada", label: "Kannada" },
  { value: "malayalam", label: "Malayalam" },
];
const addCommasToPrice = (price) => {
  // Convert price to a number
  const number = parseFloat(price);

  // Check if the number is valid
  if (!isNaN(number)) {
    // Check if the number has more than 4 digits
    if (number >= 1000) {
      // Use toLocaleString() to add commas to the number
      return number.toLocaleString();
    }
  }

  // If the number is less than 10000 or invalid, return it as is
  return price;
};
const formatPhoneNumber = (input) => {
  const phoneNumber = input?.replace(/\D/g, ""); // Remove non-numeric characters
  if (!phoneNumber) return "";

  let formattedPhoneNumber = `(${phoneNumber.slice(0, 3)})`;
  if (phoneNumber.length > 3) {
    formattedPhoneNumber += `-${phoneNumber.slice(3, 6)}-`;
  }
  if (phoneNumber.length > 6) {
    formattedPhoneNumber += `${phoneNumber.slice(6, 10)}`;
  }
  if (phoneNumber.length > 10) {
    formattedPhoneNumber += `-${phoneNumber.slice(10, 15)}`;
  }

  return formattedPhoneNumber;
};
function formatDateToCustom(dateStr) {
  // Create a new Date object
  const date = new Date(dateStr);

  // Extract components
  const day = date.getUTCDate(); // day of the month (1-31)
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const month = monthNames[date.getUTCMonth()]; // month name
  const year = date.getUTCFullYear(); // full year (e.g., 2024)

  // Extract hours and minutes
  let hours = date.getUTCHours(); // hours (00-23)
  const minutes = String(date.getUTCMinutes()).padStart(2, "0"); // minutes (00-59)
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  hours = String(hours).padStart(2, "0"); // ensure two digits for hours

  // Format into "day Month, year hh:mm AM/PM"
  return `${day} ${month}, ${year} ${hours}:${minutes} ${ampm}`;
}

const capitalizeWords = (str) => {
  return str
    ?.split(" ")
    ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    ?.join(" ");
};
export {
  formatString,
  formatCamelCaseToReadablespe,
  formatCamelCaseToReadablemedication,
  formatCamelCaseToReadabletesting,
  formatTestKeyToReadableiopphp,
  formatCamelCaseToReadabletreatmentapproaches,
  formatCamelCaseToReadableassessmentPretreatment,
  formatCamelCaseToReadableteamconsists,
  formatCamelCaseToReadableinsurance,
  formatCamelCaseToReadablesubstutanceorder,
  formatCamelCaseToReadableadditionaservices,
  formatCamelCaseToReadabcodtiontreated,
  urlCopypageresrict,
  formatCamelCaseToReadabpersonalAmenities,
  formatCamelCaseToReadabcommonAmenities,
  formatCamelCaseToReadableadditionaservicesforesidentail,
  languages,
  colourStyles,
  addCommasToPrice,
  formatPhoneNumber,
  formatDateToCustom,
  capitalizeWords,
  formatCamelCaseToReadableadditionaservicesIOP,
};
