import React, { Component } from "react";
import { SpinnerContext } from "../spinner/spinner.component";
import { Nav, Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { chatActions } from "../../actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

class FooterComponent extends Component {
  static contextType = SpinnerContext;
  componentDidMount() {
    if (
      window.location?.pathname === "/help-and-support-ios" ||
      window.location?.pathname === "/help-and-support-ios-plan" ||
      window.location.pathname.includes("/dashboard-ios") ||
      window.location?.pathname === "/terms-of-service-ios"
    ) {
    } else {
      const script = document.createElement("script");
      script.src = "https://widgets.leadconnectorhq.com/loader.js";
      script.setAttribute(
        "data-resources-url",
        "https://widgets.leadconnectorhq.com/chat-widget/loader.js"
      );
      script.setAttribute("data-widget-id", "66ec587d2cf62d7c7aa381f1");
      script.async = true;

      // Find the div where you want to load the chat widget
      const chatContainer = document.getElementById("chat-widget-container");

      // Append the script to the div
      chatContainer.appendChild(script);

      const buttonElement = document.getElementById("lc_text-widget--btn");

      if (buttonElement) {
        // Dynamically set the CSS styles
        buttonElement.style.bottom = "50px";
      }

      setTimeout(() => {
        if (this.props.hasAuthToken) {
          this.props.fetchChats();
        }
      }, 1000);
    }
  }
  unReadMessageCount = () => {
    return this.props.chats.reduce((acc, cur) => acc + cur.unreadCount, 0);
  };

  render() {
    let flag =
      window.location?.pathname === "/help-and-support-ios" ||
      window.location?.pathname === "/help-and-support-ios-plan";

    return (
      <>
        <footer>
          <div className="container">
            <div className="row">
              <div className="col-md-4 text-center">
                <img
                  src={require("../../assets/images/logo-red.svg")}
                  className="mb-2"
                  height={40}
                  alt="Taste Recovery footer logo"
                />
                <p>
                  © {new Date().getFullYear()} TasteRecovery. All Rights
                  Reserved.
                </p>
              </div>
              <div className="col-md-4">
                <ul className="footer-menu">
                  <li>
                    <Link to="/about-us">About Us</Link>
                  </li>
                  <li>
                    <Link to="/help-and-support">Help and Support</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/terms-of-service">Terms Of Use</Link>
                  </li>
                </ul>
              </div>
              <div className="col-md-4">
                <div className="download-action-wrapper text-center mb-3">
                  <Link
                    as="button"
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(
                        "https://play.google.com/store/apps/details?id=com.tasterecovery.app",
                        "_blank"
                      );
                    }}
                  >
                    <img
                      src={require("../../assets/images/google-play-button.svg")}
                      alt="Get our mobile app on Google Play"
                    />
                  </Link>
                  <Link
                    as="button"
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(
                        "https://apps.apple.com/ua/app/taste-recovery/id1504035022",
                        "_blank"
                      );
                    }}
                  >
                    <img
                      src={require("../../assets/images/app-store-button.svg")}
                      alt="Download our app on the Apple app store	"
                    />
                  </Link>
                </div>
                <ul className="footer-social-nav">
                  <li>
                    <Link
                      as="button"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(
                          "https://www.facebook.com/tasterecovery",
                          "_blank"
                        );
                      }}
                    >
                      <i className="fa fa-facebook" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      as="button"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(
                          "https://twitter.com/taste_recovery",
                          "_blank"
                        );
                      }}
                    >
                      <i className="fa fa-twitter" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      as="button"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(
                          "http://www.instagram.com/tasterecovery",
                          "_blank"
                        );
                      }}
                    >
                      <i className="fa fa-instagram" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
        {/*Mobile Bottom Menu */}

        {window.location?.pathname === "/help-and-support-ios" ||
        window.location?.pathname === "/help-and-support-ios-plan" ||
        window.location.pathname.includes("/dashboard-ios") ||
        window.location?.pathname === "/terms-of-service-ios"
          ? ""
          : this.context.isStickyFooter() && (
              <div className="mobile-menu">
                <Navbar expand="lg">
                  <div id="chat-widget-container"></div>
                  <Nav.Link as={NavLink} to="/" exact>
                    <svg
                      id="search-24px"
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                    >
                      <path id="Path" d="M0,0H24V24H0Z" fill="none" />
                      <path
                        id="Path-2"
                        className="fill-change"
                        data-name="Path"
                        d="M12.5,11h-.79l-.28-.27a6.51,6.51,0,1,0-.7.7l.27.28v.79l5,4.99L17.49,16Z"
                        transform="translate(3 3)"
                        fill="#cbcbd0"
                      />
                      <path
                        id="Path-3"
                        className="fill-change"
                        data-name="Path"
                        d="M4.5,9A4.5,4.5,0,1,1,9,4.5,4.494,4.494,0,0,1,4.5,9Z"
                        transform="translate(5 5)"
                        fill="#fff"
                      />
                    </svg>
                    {/* <img
                  src={require("../../assets/images/search-24px.svg")}
                  alt=""
                /> */}
                  </Nav.Link>
                  <Nav.Link as={NavLink} to="/scheduled-tours">
                    <svg
                      id="baseline-calendar_today-24px"
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                    >
                      <path id="Path" d="M0,0H24V24H0Z" fill="none" />
                      <path
                        id="Shape"
                        className="fill-change"
                        d="M18,22H2a2,2,0,0,1-2-2V4A2,2,0,0,1,2,2H3V0H5V2H15V0h2V2h1a2,2,0,0,1,2,2V20A2,2,0,0,1,18,22ZM2,7V20H18V7H2Z"
                        transform="translate(2 1)"
                        fill="#d7d7db"
                      />
                    </svg>
                  </Nav.Link>
                  {this.props.isHost && (
                    <Nav.Link as={NavLink} to="/profile">
                      <svg
                        id="Icon_User_24"
                        data-name="Icon User 24"
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                      >
                        <rect
                          id="Rectangle_13"
                          data-name="Rectangle 13"
                          width={24}
                          height={24}
                          fill="none"
                        />
                        <g
                          id="Group_5"
                          data-name="Group 5"
                          transform="translate(3 1)"
                        >
                          <g
                            id="Rectangle_14"
                            data-name="Rectangle 14"
                            transform="translate(0 12)"
                            fill="none"
                            strokeMiterlimit={10}
                          >
                            <path
                              d="M9,0c8.49,0,9,9,9,9H0S.51,0,9,0Z"
                              stroke="none"
                            />
                            <path
                              className="fill-change"
                              d="M 9 2 C 6.280770301818848 2 4.36338996887207 3.090750217437744 3.138299942016602 5.334579944610596 C 2.829218864440918 5.900676727294922 2.60234546661377 6.475222110748291 2.436299324035645 7 L 15.56370067596436 7 C 15.39765453338623 6.475222110748291 15.17078018188477 5.900676727294922 14.8617000579834 5.334579944610596 C 13.63661003112793 3.090750217437744 11.71923065185547 2 9 2 M 9 0 C 17.48965072631836 0 18 9 18 9 L 0 9 C 0 9 0.5103492736816406 0 9 0 Z"
                              stroke="none"
                              fill="#d7d7db"
                            />
                          </g>
                          <g
                            id="Oval_2"
                            data-name="Oval 2"
                            className="fill-change"
                            transform="translate(4)"
                            fill="none"
                            stroke="#d7d7db"
                            strokeMiterlimit={10}
                            strokeWidth={2}
                          >
                            <circle cx={5} cy={5} r={5} stroke="none" />
                            <circle cx={5} cy={5} r={4} fill="none" />
                          </g>
                        </g>
                      </svg>
                    </Nav.Link>
                  )}

                  <Nav.Link as={NavLink} to="/chats">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={25}
                      height="25.003"
                      viewBox="0 0 25 25.003"
                    >
                      <g
                        id="Icon_Messages_24"
                        data-name="Icon Messages 24"
                        transform="translate(0 1)"
                      >
                        <rect
                          id="Rectangle_10"
                          data-name="Rectangle 10"
                          width={24}
                          height={24}
                          fill="none"
                        />
                        <g
                          id="Rectangle_12"
                          data-name="Rectangle 12"
                          transform="translate(0 10)"
                          fill="none"
                          strokeMiterlimit={10}
                        >
                          <path
                            d="M0,2A2,2,0,0,1,2,0H12a2,2,0,0,1,2,2V8a2,2,0,0,1-2,2H7L2,14V10H2A2,2,0,0,1,0,8Z"
                            stroke="none"
                          />
                          <path
                            className="fill-change"
                            d="M 2 1.999997138977051 L 2 7.999997615814209 L 4 7.999997615814209 L 4 9.839678764343262 L 5.750100135803223 8.438667297363281 L 6.298069953918457 7.999997615814209 L 7 7.999997615814209 L 12 7.999997615814209 L 12 1.999997138977051 L 2 1.999997138977051 M 2 -2.86102294921875e-06 L 12 -2.86102294921875e-06 C 13.10457038879395 -2.86102294921875e-06 14 0.8954277038574219 14 1.999997138977051 L 14 7.999997615814209 C 14 9.104567527770996 13.10457038879395 9.999998092651367 12 9.999998092651367 L 7 9.999998092651367 L 2 14.00265789031982 L 2 9.999998092651367 C 0.8954296112060547 9.999998092651367 0 9.104567527770996 0 7.999997615814209 L 0 1.999997138977051 C 0 0.8954277038574219 0.8954296112060547 -2.86102294921875e-06 2 -2.86102294921875e-06 Z"
                            stroke="none"
                            fill="#d7d7db"
                          />
                        </g>
                        <path
                          className="fill-change"
                          id="Rectangle_12_Copy"
                          data-name="Rectangle 12 Copy"
                          d="M7.423,12.857H9.286l6.143,4.861V12.857A2.571,2.571,0,0,0,18,10.286V2.571A2.571,2.571,0,0,0,15.429,0H2.571A2.571,2.571,0,0,0,0,2.571V10.5"
                          transform="translate(6)"
                          fill="none"
                          stroke="#d7d7db"
                          strokeMiterlimit={10}
                          strokeWidth={2}
                        />
                      </g>
                    </svg>
                    {this.unReadMessageCount() != 0 && (
                      <sup style={{ verticalAlign: "top" }}>
                        <span className="badge btn-primary">
                          {this.unReadMessageCount()}
                        </span>
                      </sup>
                    )}
                  </Nav.Link>
                  <Nav.Link as={NavLink} to="/settings">
                    <svg
                      id="menu-24px"
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                    >
                      <path id="Path" d="M0,0H24V24H0Z" fill="none" />
                      <path
                        id="Shape"
                        className="fill-change"
                        d="M0,12V10H18v2ZM0,7V5H18V7ZM0,2V0H18V2Z"
                        transform="translate(3 6)"
                        fill="#d7d7db"
                      />
                    </svg>
                  </Nav.Link>
                </Navbar>
              </div>
            )}
        {/* Mobile Bottom Menu */}
      </>
    );
  }
}

export default connect(
  ({ chat, auth }) => ({
    hasAuthToken: auth.authToken,
    chats: chat.chats,
    isHost: auth.isHost,
  }),
  (dispatch) => {
    return {
      ...bindActionCreators({ ...chatActions }, dispatch),
    };
  }
)(FooterComponent);
