import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useContext } from "react";
import AddCardForm from "./add-card-form";
import ConfirmPayment from "./confirm-Payment";
import { SpinnerContext } from "../../components/spinner/spinner.component";

// const stripePromise = loadStripe(
//   "pk_test_51Ogiy4FxfBUFjg3cQXlv5Q03o0wMxoGfQYm1AqntJFWTNRJ7L816dHuvBPl9GKc08yBC1H6WC3orFftRWkffTHl1008qoWc7c4"
// );
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
function AddCard(props) {
  const context = useContext(SpinnerContext);
  const setPageName = (childData) => {
    context.setsubscribePage(childData);
  };

  return (
    <Elements stripe={stripePromise}>
      {props.paymentPlan === "PaymentConfirmation" ? (
        <ConfirmPayment
          clientSecret={props.clientSecret}
          childCallback={props.parentCallback}
          {...props}
          allDetails={props}
          parentCallback={setPageName}
        />
      ) : (
        <AddCardForm {...props} />
      )}
    </Elements>
  );
}

export default AddCard;
