/** @format */

import React, { Component } from "react";
import starImg from "../../assets/images/star-white.svg";
import starFillImg from "../../assets/images/star-24px.svg";
import pinImg from "../../assets/images/ic_pin_small.svg";
import defaultImg from "../../assets/images/default.png";
import {
  getCityName,
  getLocationFromItem,
  getPriceFromItem,
  getRatingsFromItem,
  Ucfirst,
} from "../../utils";
import { Link, withRouter } from "react-router-dom";
import { seoUrlText } from "../../constants";
import { SpinnerContext } from "../../components/spinner/spinner.component";


class SoberLivingCard extends Component {
  static contextType = SpinnerContext;
  constructor(props) {
    super(props);
    this.state = {
      imageUrl: "",
      ratingStr: "",
      priceStr: "",
      locationStr: "",
      heading: "",
      paymentPeriod: "",
      stateStr: "",
      isPromoted: false,
      objFit: "cover",
      isSubscribed: "",
    };
    this.onCardClick = this.onCardClick.bind(this);
    this.onImgLoad = this.onImgLoad.bind(this);
  }

  componentDidMount() {
    this.updateStateFromProps();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.item !== this.props.item) {
      this.updateStateFromProps();
    }
  }

  updateStateFromProps() {
    const item = this?.props?.item;
    this.setState({
      imageUrl: this.getImageUrlFromItem(),
      ratingStr: getRatingsFromItem(item),
      priceStr: getPriceFromItem(item),
      heading: item?.name,
      locationStr: getLocationFromItem(item),
      paymentPeriod: Ucfirst(item?.paymentPeriod),
      stateStr: item?.geocodingResult?.stateLong,
      isPromoted: item?.isPromoted,
      isPpc: item?.isPPCSubscribed,
      isSubscribed: item?.isSubscribed,
    });
  }

  deleteLiving = (id) => {
    this.context.showLoader("Deleting Sober Living..");
  };

  render() {
    const isTextLong = this.state.locationStr.length > 20;

    const { showPromoted = true } = this.props;
    let city = getCityName(this.props.item?.geocodingResult);
    return (
      <>
        <Link
          className={`card text-decoration-none ${this.props.className}`}
          to={{
            pathname: `../../living/${seoUrlText}-${city}&id=${this.props.item.id}`,
            state: { property: this.props.item },
          }}
        >
          <div
            className="card-img"
            onClick={() => {
              this.deleteLiving(this.props.item.id);
            }}
          >
            <img
              src={this.state.imageUrl}
              style={{ objectFit: this.state.objFit }}
              onLoad={this.onImgLoad}
              onError={() => this.setState({ imageUrl: defaultImg })}
              alt=""
            />
            {this.props.showActionItems && (
              <span className="card-actions">
                <button
                  className="owner-card-action-button"
                  onClick={(e) => {
                    this.props.onScheduleTour();
                    e.preventDefault();
                  }}
                >
                  <svg
                    id="baseline-calendar_today-24px"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path id="Path" d="M0,0H24V24H0Z" fill="none"></path>
                    <path
                      id="Shape"
                      className="fill-change"
                      d="M18,22H2a2,2,0,0,1-2-2V4A2,2,0,0,1,2,2H3V0H5V2H15V0h2V2h1a2,2,0,0,1,2,2V20A2,2,0,0,1,18,22ZM2,7V20H18V7H2Z"
                      transform="translate(2 1)"
                      fill="#D7D7DB"
                    ></path>
                  </svg>
                </button>
                <button
                  className="owner-card-action-button"
                  onClick={(e) => {
                    this.props.history.push(
                      `/living/edit-living/${this.props.item.id}`
                    );
                    e.preventDefault();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                  >
                    <path
                      id="edit"
                      d="M0,18V14.251L11.058,3.192l3.749,3.749L3.749,18ZM12.128,2.122l1.83-1.83a1,1,0,0,1,1.409,0l2.34,2.34a1,1,0,0,1,0,1.409l-1.83,1.83Z"
                      transform="translate(0 0)"
                      fill="#232330"
                      className="fill-change"
                    ></path>
                  </svg>
                </button>
                <button
                  className="owner-card-action-button"
                  onClick={(e) => {
                    this.props.onDeleteLiving();
                    e.preventDefault();
                  }}
                >
                  <svg
                    height="20px"
                    viewBox="-48 0 407 407"
                    width="24px"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      className="fill-change"
                      d="m89.199219 37c0-12.132812 9.46875-21 21.601562-21h88.800781c12.128907 0 21.597657 8.867188 21.597657 21v23h16v-23c0-20.953125-16.644531-37-37.597657-37h-88.800781c-20.953125 0-37.601562 16.046875-37.601562 37v23h16zm0 0"
                    ></path>
                    <path
                      className="fill-change"
                      d="m60.601562 407h189.199219c18.242188 0 32.398438-16.046875 32.398438-36v-247h-254v247c0 19.953125 14.15625 36 32.402343 36zm145.597657-244.800781c0-4.417969 3.582031-8 8-8s8 3.582031 8 8v189c0 4.417969-3.582031 8-8 8s-8-3.582031-8-8zm-59 0c0-4.417969 3.582031-8 8-8s8 3.582031 8 8v189c0 4.417969-3.582031 8-8 8s-8-3.582031-8-8zm-59 0c0-4.417969 3.582031-8 8-8s8 3.582031 8 8v189c0 4.417969-3.582031 8-8 8s-8-3.582031-8-8zm0 0"
                    ></path>
                    <path
                      className="fill-change"
                      d="m20 108h270.398438c11.046874 0 20-8.953125 20-20s-8.953126-20-20-20h-270.398438c-11.046875 0-20 8.953125-20 20s8.953125 20 20 20zm0 0"
                    ></path>
                  </svg>
                </button>
              </span>
            )}
            {this.props.pageName === "Profile" ? (
              <div>
                {this.props.isHost == true ? (
                  this.state.isSubscribed === true ? (
                    <span className="subscription-msg">Pro</span>
                  ) : (
                    <span className="subscription-msg unsubscribed">Basic</span>
                  )
                ) : (
                  <span className="subscription-msg unsubscribed">
                    Not Subscribed
                  </span>
                )}
              </div>
            ) : (
              ""
            )}

            {/* <span className='subscription-msg unsubscribed'>Unsubscribed</span> */}
            {showPromoted && (this.state.isPromoted || this.state.isPpc) && (
              <span className="promote-ad-msg">
                <span>Ad </span>
              </span>
            )}
            {!this.props.isRecommended &&
              !this.props.ratingOutside &&
              !this.props.showRating && (
                <span className="rating">
                  <img src={starImg} alt="" />
                  {this.state.ratingStr}
                </span>
              )}
          </div>
          {!this.props.isRecommended ? (
            <div className="card-body">
              <div className="property-wrapper float-left">
                <div className="card-heading">
                  <span>{this.state.heading} </span>
                  {/* <span>${this.state.priceStr}</span> */}
                </div>
                <div className="card-content">
                  <span className="location" style={{ display: "flex" }}>
                    <img src={pinImg} alt="" />{" "}
                    {isTextLong
                      ? `${this.state.locationStr.slice(0, 20)}...`
                      : this.state.locationStr}
                  </span>
                  {this.state.isSubscribed == true && (
                    <span className="rating-recommended">
                      <img src={starFillImg} className="starImg" alt="" />{" "}
                      {Number(this.state.ratingStr).toFixed(1)}
                    </span>
                  )}
                </div>
              </div>
              <div className="amount-wrapper float-right">
                <span>
                  ${`${this.state.priceStr.split("/")[0]}`}
                  <span className="d-block">{`${
                    this.state.priceStr.split("/")[1]
                  }`}</span>
                </span>
                {this.props.savedHome && (
                  <img
                    src={require("../../assets/images/bookmark-select.svg")}
                    alt=""
                    width="30"
                    height="30"
                  />
                )}
              </div>
            </div>
          ) : (
            <div className="card-body">
              <div className="recommended-box">
                <div className="box-left">
                  <div className="card-heading  card-heading-flex">
                    {this.state.heading}
                  </div>
                  <div className="card-content">
                    <span className="location" style={{ display: "flex" }}>
                      <img src={pinImg} alt="" />{" "}
                      {isTextLong
                        ? `${this.state.locationStr.slice(0, 20)}...`
                        : this.state.locationStr}
                    </span>
                  </div>
                  {this.state.isSubscribed === true ? (
                    <span className="rating-recommended">
                      <img src={starFillImg} alt="" />{" "}
                      {Number(this.state.ratingStr).toFixed(1)}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="box-right">
                  <span className="value value-recommended">
                    <span>
                      ${`${this.state.priceStr.split("/")[0]}`}
                      <span className="d-block">{`${
                        this.state.priceStr.split("/")[1]
                      }`}</span>
                    </span>
                  </span>
                  {/* <span className="lbl"> Per {this.state.paymentPeriod}</span> */}
                </div>
              </div>
            </div>
          )}
        </Link>
      </>
    );
  }

  getImageUrlFromItem() {
    const item = this.props.item;
    if (!item || !item.photos || item.photos.length === 0) {
      return " ";
    }
    const mainImage = item.photos.find((photo) => photo.isMain);
    if (!mainImage.thumbnails || mainImage.thumbnails.length === 0) {
      return mainImage.path;
    }

    return mainImage.thumbnails["thumb360w"];
  }

  onCardClick() {
    let city = getCityName(this.props.item?.geocodingResult);
    this.props.history.push(
      `living/sober-living-in${city}&id=${this.props.item.id}`
    );
  }

  onImgLoad({ target: img }) {
    try {
      if (img.naturalWidth < img.offsetWidth) {
        // this.setState({ objFit: "fill" })
        this.setState({ objFit: "cover" });
      } else {
        this.setState({ objFit: "cover" });
      }
    } catch (e) {}
  }
}

export default withRouter(SoberLivingCard);
