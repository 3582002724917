import React, { useContext, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { SpinnerContext } from "../../components/spinner/spinner.component";
import { SERVER_APIS } from "../../constants";
import axios from "axios"; 
import { livingAction } from "../../actions";
import { useDispatch } from "react-redux";
import moment from "moment";
const CancelConfirmationModal = ({ open, onCancel,facilityType,subscriptionId,userId ,facilityId,currentPlanEnd }) => {
  const dispatch=useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { showLoader} = useContext(SpinnerContext);
  const [errorMessage, setErrorMessage] = useState("");
 const [cancelMessage,setCancelMessage]= useState("")
  const normalSubsciptionFunc = async() => {
          const updatedServerApi={
              ...SERVER_APIS,
              facilityCenter: `/${facilityType}`
          }
        const valueFacility = {
            subscriptionId: subscriptionId,
            userId: userId
      };
      
      var facilityOption = {
        method: "post",
        url: `${updatedServerApi.facilityCenter}/${facilityId}/cancel/subscription`,
        data: valueFacility,
        headers: {
          "Content-Type": "application/json",
        },
      };
    
     await axios(facilityOption)
        .then(async (res) => {
          setCancelMessage(res?.data?.message)
        })
        .catch((error) => {
          alert(error);
        });
        setErrorMessage(true);
        setTimeout(()=>{
            setErrorMessage(false);
          },6000);
        onCancel();
        await dispatch(livingAction.fetchhostLivings());
    }

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}
const originalString = "Are you sure you want to cancel your subscription?";
 const transformedString = capitalizeFirstLetter(originalString);
  const trashIconStyle = {
    position: "absolute",
    overflow: "hidden",
    left: "-4px",
    right: "-4px",
    bottom: "100%",
    height: "40px",
    zIndex: "1",
    transform: "translateY(2px)"
  };

  return (
    <>
      {errorMessage && (
        <Modal
          centered
          show={setErrorMessage}
          onHide={() => { }}
          className="upgrade-plan cancel-subscription-modal"
        >
          <div className="upgrade-plan-content d-flex flex-column align-items-center">
            <span className="loading-msg">{cancelMessage}</span>
          </div>

        </Modal>)
      }

    {open &&
    <div className="modal upgrade-plan cancel-subscription-modal fade show" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="upgrade-plan-content">
                        <span className="loading-msg">Are you sure you want to cancel subscription? This will disable your current subscription plan features.</span>
                    </div>
                    <div className="modal-footer">
                        <button type="submit" className="modal-btn" onClick={onCancel}>No</button>
                        <button type="submit" className="modal-btn confirm-btn" onClick={normalSubsciptionFunc} disabled={isLoading}>Yes</button>
                    </div>
                </div>
            </div>
        </div>
}
    </>
  );
};

export default CancelConfirmationModal;