import React, { useState, useEffect, useContext, useRef } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import Rater from "react-rater";
import Avatar from "react-avatar";
import Moment from "react-moment";
import NoImageDetail from "../../../assets/images/No-image-details-screen.svg";
import backImgBlackWhite from "../../../assets/images/back-white-arrow.svg";
import GoogleMapComponent from "../../../components/map/google-map.component";
import pinImg from "../../../assets/images/ic_pin_small.svg";
import starImg from "../../../assets/images/star-24px.svg";
import replyImg from "../../../assets/images/reply.svg";
import children from "../../../assets/images/Children.svg";
import couples from "../../../assets/images/Couples.svg";
import pregWomen from "../../../assets/images/Pregnant women.svg";
import Scholarships from "../../../assets/images/Scholarship.svg";
import serviceDogs from "../../../assets/images/Service Dogs.svg";
import tickMarkIcon from "../../../assets/images/tick-mark.svg";
import aboutTitle from "../../../assets/images/aboutTitle.svg";
import specializesIcon from "../../../assets/images/specializes-in.svg";
import servicesOfferedIcon from "../../../assets/images/additional-services-offered.svg";
import genderTypeIcon from "../../../assets/images/gender-type.svg";
import conditionsIcon from "../../../assets/images/conditions-treated.svg";
import medicationIcon from "../../../assets/images/medication-therapy.svg";
import approachesIcon from "../../../assets/images/treatment-approaches.svg";
import assessmentIcon from "../../../assets/images/assessment-pre-treatment.svg";
import testingIcon from "../../../assets/images/testing.svg";
import smokingIcon from "../../../assets/images/facility-smoking-policy.svg";
import staffIcon from "../../../assets/images/staff-associated.svg";
import insuranceIcon from "../../../assets/images/insurance-accepted.svg";
import { SpinnerContext } from "../../../components/spinner/spinner.component";
import { useSelector, useDispatch } from "react-redux";
import { homeAction } from "../../../actions";
import ViewField from "../../../components/viewField/viewField.component";

import {
  formatCamelCaseToReadablemedication,
  formatCamelCaseToReadableteamconsists,
  formatCamelCaseToReadableinsurance,
  formatCamelCaseToReadableadditionaservices,
  formatCamelCaseToReadableadditionaservicesIOP,
  formatCamelCaseToReadableadditionaservicesforPHP,
  formatCamelCaseToReadableadditionaservicesforesidentailResidential,
  formatCamelCaseToReadableassessmentPretreatment,
  formatCamelCaseToReadabletreatmentapproaches,
  formatCamelCaseToReadabcodtiontreated,
  formatCamelCaseToReadablespe,
  formatCamelCaseToReadabcommonAmenities,
  addCommasToPrice,
  formatString,
  formatTestKeyToReadableiopphp,
} from "../../../constants/utils";
import ContactInfoCard from "./contactInfoCard";
import { livingAction } from "../../../actions";
import showAll from "../../../assets/images/show-all.svg";
import Slider from "react-slick";
import backImgBlack from "../../../assets/images/back.svg";
import stayRange from "../../../assets/images/Range of term of stay.svg";
import commonAmenities from "../../../assets/images/Common Amenities.svg";
import personalAmenities from "../../../assets/images/Personal Amenities.svg";
import clientToClinical from "../../../assets/images/Client to clinical team ratio.svg";
import maxCapacity from "../../../assets/images/Maximum capacity.svg";
import primaryLanguage from "../../../assets/images/Primary language.svg";
import otherLanguages from "../../../assets/images/Other languages.svg";
import bookmarkselect from "../../../assets/images/bookmark-select.svg";
import bookmarkunselect from "../../../assets/images/bookmark_border-24px copy.svg";
import ClaimFacility from "../claimFacilityView";
import canclePrimaryImg from "../../../assets/images/cancle-primary.svg";
import Questions from "../../../components/viewField/questions.component";
import SliderNextArrow from "../../../components/slider/imageSliderNext";
import SliderPrevArrow from "../../../components/slider/imageSliderPrev";
import RecommendedFacility from "../../../components/home/recommendedFacility";
import axios from "axios";
import { SERVER_APIS } from "../../../constants";
import noReviewImg from "../../../assets/images/no-review-image.svg";
const ViewDetailsFacilityCenter = () => {
  const state = useSelector((state) => state);

  const isHost = state?.auth?.isHost;
  const user = state?.auth?.user?.name;

  const comp = useParams();
  const history = useHistory();
  const { showLoader, hideLoader, showLogin } = useContext(SpinnerContext);
  const dispatch = useDispatch();
  const [getData, setGetData] = useState([]);
  const [showmodel, setshowmodel] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [coordinates, setCoordinates] = useState({ lat: 0, lng: 0 });

  const [rating, setRating] = useState("");
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [isReply, setIsReply] = useState(false);
  const [replyId, setReplyId] = useState();
  const [replyReview, setReplyReview] = useState({ content: "" });
  const [comment, setComment] = useState("");
  const [isReviewAdd, setISReveiewAdd] = useState(false);
  const [getReview, setGetReview] = useState([]);

  const [activeArea, setActiveArea] = useState();
  const [hidemodal, sethidemodal] = useState(true);
  const [displayAddReview, setDisplayAddReview] = useState(true);
  const [recommendedFacilities, setRecommendedFacilities] = useState([]);
  const userName = useSelector((state) => state.auth.user?.name);

  var settings1 = {
    dots: true,
    infinite: true,
    speed: 300,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SliderNextArrow />,
    prevArrow: <SliderPrevArrow />,
  };
  useEffect(() => {
    ShowData(comp?.id);
  }, [comp?.id]);

  const checkLogin = async () => {
    if (userName?.name) {
      setDisplayAddReview(true);
    } else {
      await showLogin(true);
      setDisplayAddReview(false);
    }
  };

  useEffect(() => {
    const fetchRecommendedHouse = async () => {
      try {
        const response = await axios.get(
          `${SERVER_APIS.recommendedFacilityListById}/${comp?.id}`
        );
        setRecommendedFacilities(response?.data?.data);
      } catch (error) {
        console.error("Error fetching recommended house data:", error);
      }
    };

    if (comp?.id) {
      fetchRecommendedHouse();
    }
  }, [comp?.id]);

  const fetchReview = async () => {
    if (isReviewAdd) {
      showLoader("adding your review.....");
    }

    if (isReply) {
      showLoader("replying to review.....");
    }
    const data = await dispatch(livingAction.fetchLivingReviewsById(comp?.id));
    hideLoader("");
    setGetReview(data);
    setISReveiewAdd(false);
  };

  useEffect(() => {
    fetchReview(comp.id);
  }, [isReviewAdd, comp?.id]);

  const handleReplyReview = async (replyReview, id, getData) => {
    dispatch(livingAction.addReviewReply(replyReview, id, getData));

    if (isReply) {
      await dispatch(livingAction.fetchLivingReviewsById(comp?.id));
    }
    //  setISReplyAdd
    fetchReview(comp?.id);
    setIsReply(false);
    setReplyReview({ content: "" });
  };

  useEffect(() => {
    const sectionIds = [
      "about",
      "services",
      "staffAssociate",
      "insurance",
      "reviews",
    ];

    const handleScroll = () => {
      const offset = 100;
      for (const id of sectionIds) {
        const section = document.getElementById(id);
        if (section) {
          const rect = section.getBoundingClientRect();
          if (rect.top >= offset && rect.top < window.innerHeight) {
            setActiveArea(id);
            break;
          }
        }
      }
    };

    const debouncedHandleScroll = debounce(handleScroll, 100);

    window.addEventListener("scroll", debouncedHandleScroll);
    return () => {
      window.removeEventListener("scroll", debouncedHandleScroll);
    };
  }, []);

  const debounce = (func, wait) => {
    let timeout;
    return function (...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  const onSubmitReview = () => {
    const review = {
      user: user,
      value: rating,
      comment: comment,
      livedFrom: fromDate,
      livedTo: toDate,
      property: comp?.id,
      isAnonymous: false,
    };

    dispatch(livingAction.addReview(review));

    setISReveiewAdd(true);
    setRating("");
    setFromDate("");
    setToDate("");
    setComment("");
    fetchReview(comp?.id);
  };

  const ShowData = async (id) => {
    setRating("");
    showLoader("Fetching facility center details... ");
    let data;
    if (userName) {
      data = await dispatch(homeAction.getFacilityDataById(id, history));
    } else {
      data = await dispatch(homeAction.getFacilityDataByIdWithoutLogin(id));
    }
    document.title = data?.name;
    setGetData(data);
    if (userName) {
      dispatch(homeAction.getFacilityRecentViewDetail(comp?.id));
    }
    setCoordinates({
      lat: data?.coordinates?.lat,

      lng: data?.coordinates?.lat,
    });

    hideLoader("");
  };

  const saveFacilitycenter = async () => {
    dispatch(livingAction.onBookmarkLivingFacility(comp?.id));
    showLoader("Fetching facility center details... ");
    let data;
    if (userName) {
      data = await dispatch(homeAction.getFacilityDataById(comp?.id));
    } else {
      data = await dispatch(
        homeAction.getFacilityDataByIdWithoutLogin(comp?.id)
      );
      showLogin(true);
    }
    ShowData(comp?.id);

    setTimeout(() => {
      if (data) {
        hideLoader("");
      }
    }, 1000);
  };

  const saveFacilitycenterRemove = async () => {
    dispatch(livingAction.onBookmarkLivingFacilityRemove(comp?.id));
    showLoader("Fetching facility center details... ");
    let data;
    if (userName) {
      data = await dispatch(homeAction.getFacilityDataById(comp?.id));
    } else {
      data = await dispatch(
        homeAction.getFacilityDataByIdWithoutLogin(comp?.id)
      );
      showLogin(true);
    }
    ShowData(comp?.id);
    setTimeout(() => {
      if (data) {
        hideLoader("");
      }
    }, 1000);
  };

  const scrollToInsurance = (identifier) => {
    const activeSection = document.getElementById(identifier);
    activeSection.scrollIntoView({ behavior: "smooth" });
    setActiveArea(identifier);
  };

  var gallerySlider = {
    dots: true,
    infinite: false,
    speed: 300,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    swipeToSlide: true,
    afterChange: (current) => setCurrentSlide(current),
  };

  var recommendedSlider = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 300,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (current) => setCurrentSlide(current),
  };

  useEffect(() => {
    if (!state?.auth?.isHost) {
      if (state?.auth?.authToken) {
        dispatch(
          homeAction.ppcClickBySignInUser({ facilityOrSoberLivingId: comp?.id })
        );
      } else {
        dispatch(
          homeAction.ppcClickNonAuth({ facilityOrSoberLivingId: comp?.id })
        );
      }
    }
  }, [comp?.id]);

  return (
    <>
      <section className="form-view-banner">
        <div className="banner-image">
          {getData?.photos?.length > 0 ? (
            getData?.photos?.map((obj, index) => {
              return (
                obj?.isMain && (
                  <React.Fragment key={index}>
                    <img
                      src={obj?.path}
                      className="img-fluid"
                      alt={getData?.name}
                    />
                  </React.Fragment>
                )
              );
            })
          ) : (
            <>
              <img
                src={NoImageDetail}
                className="img-fluid"
                alt="NoImageDetail"
              />
            </>
          )}
        </div>
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-md-12 h-100">
              <div className="photos-banner-content d-flex flex-column justify-content-between h-100">
                <a
                  // onClick={() => {
                  //   history.push(`/${getData?.facilityType}`, {
                  //     state: getData?.facilityType,
                  //   });
                  // }}

                  onClick={() => {
                    history.goBack();
                  }}
                  role="button"
                  className="form-view-back"
                >
                  <img src={backImgBlackWhite} className="img-fluid" alt="" />
                </a>
                {getData?.photos?.length !== 0 && (
                  <div className="show-all-photos w-100 text-right">
                    <button
                      type="button"
                      className="btn btn-default d-flex align-items-center radius-20 m-0 ml-auto"
                      onClick={() => setshowmodel(true)}
                    >
                      <img src={showAll} className="img-fluid" alt="" />
                      Show all Photos
                    </button>
                    <ClaimFacility
                      facilityId={getData?.id}
                      Facilityname={getData?.name}
                      dashboard="no"
                      flag={
                        getData?.isSubscribed || getData?.isPreSaleSubscribed
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="form-view-tabs-sec pt-0">
        <div className="form-view-tabs d-none d-md-block">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ul className="tab-list d-flex align-items-center p-0 m-0">
                  <li
                    className={
                      activeArea === "about" ? "tab-item active" : "tab-item"
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="tab-link"
                      onClick={() => scrollToInsurance("about")}
                    >
                      About
                    </span>
                  </li>
                  <li
                    className={
                      activeArea === "services" ? "tab-item active" : "tab-item"
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="tab-link"
                      onClick={() => scrollToInsurance("services")}
                    >
                      Services
                    </span>
                  </li>
                  <li
                    className={
                      activeArea === "staffAssociate"
                        ? "tab-item active"
                        : "tab-item"
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="tab-link"
                      onClick={() => scrollToInsurance("staffAssociate")}
                    >
                      Staff Associated
                    </span>
                  </li>
                  <li
                    className={
                      activeArea === "insurance"
                        ? "tab-item active"
                        : "tab-item"
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="tab-link"
                      onClick={() => scrollToInsurance("insurance")}
                    >
                      Insurance
                    </span>
                  </li>
                  <li
                    className={
                      activeArea === "reviews" ? "tab-item active" : "tab-item"
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="tab-link"
                      onClick={() => scrollToInsurance("reviews")}
                    >
                      Reviews
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="form-view-content">
          <div className="info-icon d-none d-md-block">
            <div className="container">
              <div className="row">
                <div className="col-md-12"></div>
              </div>
            </div>
          </div>
          <div id="about" className="form-view-top position-relative">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="facility-content d-flex flex-wrap justify-content-center justify-content-md-between">
                    <div className="facility-info flex-fill">
                      <h3 className="mt-0">
                        {getData?.name
                          ?.split(" ")
                          ?.map(
                            (word) =>
                              word.charAt(0).toUpperCase() +
                              word.slice(1).toLowerCase()
                          )
                          .join(" ")}{" "}
                        {getData?.isVerified && (
                          <img
                            src={tickMarkIcon}
                            className="img-fluid"
                            alt=""
                          />
                        )}
                        <span
                          onClick={
                            getData?.isMyFavorite
                              ? saveFacilitycenterRemove
                              : saveFacilitycenter
                          }
                        >
                          <img
                            src={
                              getData?.isMyFavorite
                                ? bookmarkselect
                                : bookmarkunselect
                            }
                            alt=""
                            width="30"
                            height="30"
                          />
                        </span>{" "}
                      </h3>{" "}
                      <span className="location">
                        <img src={pinImg} className="img-fluid m-0" alt="" />
                        {getData?.geocodingResult?.cityName}
                        {", "}
                        {getData?.geocodingResult?.stateShort}
                        {", "}
                        {getData?.geocodingResult?.country}
                      </span>
                      {(getData?.preSalePlanType === "pro" ||
                        getData?.subscriptionDetail?.subscriptionType ===
                          "pro" ||
                        getData?.subscriptionDetail?.subscriptionType ===
                          "basic") && (
                        <span className="location">
                          <img src={starImg} className="img-fluid m-0" alt="" />
                          {getData?.rating?.value !== 0 ? (
                            <>
                              <span>{getData?.rating?.value?.toFixed(1)}</span>
                            </>
                          ) : (
                            <>
                              <span>0.0</span>
                            </>
                          )}
                        </span>
                      )}
                      {(getData?.rentRange?.from || getData?.rentRange?.to) && (
                        <span className="location">
                          {/* <img src={usdIcon} className="img-fluid m-0" alt="" /> */}
                          ${addCommasToPrice(getData?.rentRange?.from)}
                          &nbsp;-&nbsp; $
                          {addCommasToPrice(getData?.rentRange?.to)}
                        </span>
                      )}
                      {getData?.about && (
                        <div
                          className={
                            (getData?.isClaimed === true &&
                              getData?.isClaimRequest === true) ||
                            (getData?.isClaimed === false &&
                              getData?.isClaimRequest === false)
                              ? "about-content"
                              : "about-content border-left-transform"
                          }
                        >
                          <h3 className="d-flex align-items-center">
                            <img
                              src={aboutTitle}
                              className="img-fluid"
                              alt=""
                            />{" "}
                            About
                          </h3>
                          <p>{getData?.about}</p>
                        </div>
                      )}
                    </div>
                    {(getData?.isClaimed === true &&
                      getData?.isClaimRequest === true) ||
                    (getData?.isClaimed === false &&
                      getData?.isClaimRequest === false) ? (
                      <ContactInfoCard data={getData} />
                    ) : (
                      <div className="info-icon pt-2 pt-md-3">
                        <div className="container">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="claim-facility-btn">
                                <button
                                  type="button"
                                  className="btn btn-primary radius-20 mt-20 justify-content-center"
                                >
                                  Unclaimed Facility
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form-view-detail">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="facility-content">
                    {getData?.about && (
                      <div
                        className={
                          (getData?.isClaimed === true &&
                            getData?.isClaimRequest === true) ||
                          (getData?.isClaimed === false &&
                            getData?.isClaimRequest === false)
                            ? "d-none"
                            : "about-content border-left-transform"
                        }
                      >
                        <h3 className="d-flex align-items-center">
                          <img src={aboutTitle} className="img-fluid" alt="" />{" "}
                          About
                        </h3>
                        <p>{getData?.about}</p>
                      </div>
                    )}
                    <h3 className="mt-0 mt-md-2 text-left">Location</h3>
                    <span className="location">
                      <img src={pinImg} className="img-fluid m-0" alt="" />
                      {getData?.geocodingResult?.cityName}
                      {", "}
                      {getData?.geocodingResult?.stateShort}
                      {", "}
                      {getData?.geocodingResult?.country}
                    </span>
                    <div className="map-view form-group mt-3 pt-1">
                      <GoogleMapComponent
                        className="details-map"
                        markers={[getData?.coordinates || { lat: 0, lng: 0 }]}
                        newCenter={{
                          lat: getData?.coordinates?.lat
                            ? getData?.coordinates?.lat
                            : 0,
                          lng: getData?.coordinates?.lng
                            ? getData?.coordinates?.lng
                            : 0,
                        }}
                        handleClick={(e) => {
                          setCoordinates({ lat: e.lat, lng: e.lng });
                        }}
                      />
                    </div>
                    {(getData?.isClaimed === true &&
                      getData?.isClaimRequest === true) ||
                    (getData?.isClaimed === false &&
                      getData?.isClaimRequest === false) ? (
                      <ContactInfoCard data={getData} />
                    ) : (
                      ""
                    )}
                  </div>

                  <div id="services">
                    {getData?.allowableStay?.from &&
                      getData?.allowableStay?.to && (
                        <div className="facility-content">
                          <h3 className="d-flex align-items-center">
                            {" "}
                            <img src={stayRange} className="img-fluid" alt="" />
                            Range Of Term Of Stay{" "}
                          </h3>
                          <ul className="d-flex align-items-start flex-wrap">
                            <li>
                              {getData?.allowableStay?.from} days -{"  "}
                              {getData?.allowableStay?.to} days
                            </li>
                          </ul>
                        </div>
                      )}

                    {getData?.maximumCapacity !== 0 &&
                      getData?.maximumCapacity !== null && (
                        <div className="facility-content">
                          <h3 className="d-flex align-items-center">
                            <img
                              src={maxCapacity}
                              className="img-fluid"
                              alt=""
                            />
                            Maximum Capacity
                          </h3>
                          <ul className="d-flex align-items-start flex-wrap">
                            <li>{getData?.maximumCapacity}</li>
                          </ul>
                        </div>
                      )}

                    {getData?.specialization &&
                      Object.values(getData?.specialization).some(
                        (value) => value === true
                      ) && (
                        <ViewField
                          img={specializesIcon}
                          data={getData?.specialization}
                          title="Specializes In"
                          format={formatCamelCaseToReadablespe}
                        />
                      )}

                    {getData?.additionalServiceOffered &&
                      Object.values(getData?.additionalServiceOffered).some(
                        (value) => value === true
                      ) && (
                        <ViewField
                          img={servicesOfferedIcon}
                          data={getData?.additionalServiceOffered}
                          title="Additional Services Offered"
                          format={
                            getData?.facilityType === "detox"
                              ? formatCamelCaseToReadableadditionaservices
                              : getData?.facilityType === "iop"
                              ? formatCamelCaseToReadableadditionaservices
                              : getData?.facilityType === "residential"
                              ? formatCamelCaseToReadableadditionaservicesforesidentailResidential
                              : formatCamelCaseToReadableadditionaservices
                          }
                        />
                      )}

                    {getData?.personalAmenities &&
                      Object.values(getData?.personalAmenities).some(
                        (value) => value === true
                      ) && (
                        <ViewField
                          img={personalAmenities}
                          data={getData?.personalAmenities}
                          title="Personal Amenities"
                          format={formatCamelCaseToReadableadditionaservices}
                        />
                      )}

                    {getData?.commonAmenities &&
                      Object.values(getData?.commonAmenities).some(
                        (value) => value === true
                      ) && (
                        <ViewField
                          img={commonAmenities}
                          data={getData?.commonAmenities}
                          title="Common Amenities"
                          format={formatCamelCaseToReadableadditionaservices}
                        />
                      )}

                    {getData?.primaryLanguage &&
                      (getData?.primaryLanguage != "" ||
                        getData?.primaryLanguage !== null) && (
                        <div className="facility-content">
                          <h3 className="d-flex align-items-center">
                            <img
                              src={primaryLanguage}
                              className="img-fluid"
                              alt=""
                            />
                            Primary Language
                          </h3>
                          <ul className="d-flex align-items-start flex-wrap">
                            <li>
                              {getData?.primaryLanguage &&
                                getData.primaryLanguage
                                  .charAt(0)
                                  .toUpperCase() +
                                  getData.primaryLanguage.slice(1)}
                            </li>
                          </ul>
                        </div>
                      )}
                    {getData?.isOfferedOtherlanguages &&
                      getData?.otherlanguages?.length !== 0 && (
                        <div className="facility-content">
                          <h3 className="d-flex align-items-center">
                            <img
                              src={otherLanguages}
                              className="img-fluid"
                              alt=""
                            />
                            Other Languages
                          </h3>
                          <ul className="d-flex align-items-start flex-wrap">
                            {getData?.otherlanguages?.map((language, key) => (
                              <li key={key}>
                                {formatCamelCaseToReadabcommonAmenities(
                                  language
                                )}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    {getData?.genderSpecific === true &&
                      getData?.gender !== "anybody" && (
                        <div className="facility-content">
                          <h3 className="d-flex align-items-center">
                            <img
                              src={genderTypeIcon}
                              className="img-fluid"
                              alt=""
                            />{" "}
                            Gender Type
                          </h3>
                          <ul className="d-flex align-items-start flex-wrap">
                            <li>
                              {getData?.gender === "men"
                                ? "Male"
                                : "" || getData?.gender === "women"
                                ? "Female"
                                : "" || getData?.gender === "lgbtq"
                                ? "LGBTQ"
                                : ""}
                            </li>
                          </ul>
                        </div>
                      )}

                    {getData?.conditionTreated &&
                      Object.values(getData?.conditionTreated).some(
                        (value) => value === true
                      ) && (
                        <ViewField
                          img={conditionsIcon}
                          data={getData?.conditionTreated}
                          title="Conditions Treated"
                          format={formatCamelCaseToReadabcodtiontreated}
                        />
                      )}

                    {getData?.medicationTherapy &&
                      Object.values(getData?.medicationTherapy).some(
                        (value) => value === true
                      ) && (
                        <ViewField
                          img={medicationIcon}
                          data={getData?.medicationTherapy}
                          title="Medication Therapy"
                          format={formatCamelCaseToReadablemedication}
                        />
                      )}

                    {getData?.treatmentApproaches &&
                      Object.values(getData?.treatmentApproaches).some(
                        (value) => value === true
                      ) && (
                        <ViewField
                          img={approachesIcon}
                          data={getData?.treatmentApproaches}
                          title="Treatment Approaches"
                          format={formatCamelCaseToReadabletreatmentapproaches}
                        />
                      )}

                    {getData?.preTreatment &&
                      Object.values(getData?.preTreatment).some(
                        (value) => value === true
                      ) && (
                        <ViewField
                          img={assessmentIcon}
                          data={getData?.preTreatment}
                          title="Assessment/ Pre-treatment"
                          format={
                            formatCamelCaseToReadableassessmentPretreatment
                          }
                        />
                      )}

                    {getData?.testing &&
                      Object.values(getData?.testing).some(
                        (value) => value === true
                      ) && (
                        <ViewField
                          img={testingIcon}
                          data={getData?.testing}
                          title="Testing"
                          format={formatTestKeyToReadableiopphp}
                        />
                      )}

                    {getData?.smokingPolicy &&
                      Object.values(getData?.smokingPolicy).some(
                        (value) => value === true
                      ) && (
                        <ViewField
                          img={smokingIcon}
                          data={getData?.smokingPolicy}
                          title="Facility Smoking Policy"
                          format={formatString}
                        />
                      )}

                    {getData?.facilityType !== "detox" && (
                      <Questions
                        image={serviceDogs}
                        heading="Allows Service Dogs"
                        details={getData?.serviceDogs}
                      />
                    )}
                    {getData?.facilityType !== "detox" && (
                      <Questions
                        image={couples}
                        heading="Allows Couples"
                        details={getData?.couples}
                      />
                    )}
                    {getData?.facilityType !== "detox" && (
                      <Questions
                        image={pregWomen}
                        heading="Allows Pregnant Women"
                        details={getData?.pregnantWomen}
                      />
                    )}
                    {getData?.facilityType !== "detox" && (
                      <Questions
                        image={children}
                        heading="Allows Children"
                        details={getData?.children}
                      />
                    )}
                    <Questions
                      image={Scholarships}
                      heading="Offers Scholarships"
                      details={getData?.offersScholarships}
                    />
                  </div>
                  <div id="staffAssociate">
                    {getData?.teamConsist &&
                      Object.values(getData?.teamConsist).some(
                        (value) => value === true
                      ) && (
                        <ViewField
                          img={staffIcon}
                          data={getData?.teamConsist}
                          title="Staff Associated"
                          format={formatCamelCaseToReadableteamconsists}
                        />
                      )}

                    {getData?.clients !== 0 &&
                      getData?.clinical !== 0 &&
                      getData?.clients &&
                      getData?.clinical && (
                        <div className="facility-content">
                          <h3 className="d-flex align-items-center">
                            <img
                              src={clientToClinical}
                              className="img-fluid"
                              alt=""
                            />
                            Client To Clinical Team Ratio
                          </h3>
                          <ul className="d-flex align-items-start flex-wrap">
                            <li>
                              {getData?.clients}{" "}
                              {getData?.clients === 1 ? "Client" : "Clients"} :{" "}
                              {getData?.clinical}{" "}
                              {getData.clinical === 1
                                ? "Clinician"
                                : "Clinicians"}{" "}
                            </li>
                          </ul>
                        </div>
                      )}
                  </div>

                  <div id="insurance">
                    {getData?.insurance &&
                      Object.values(getData?.insurance).some(
                        (value) => value === true
                      ) && (
                        <ViewField
                          img={insuranceIcon}
                          data={getData?.insurance}
                          title="Insurance Accepted"
                          format={formatCamelCaseToReadableinsurance}
                          verifyInsurance={getData?.verifyInsurance}
                        />
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {(getData?.isSubscribed ||
            getData?.isPreSaleSubscribed ||
            getData?.subscriptionDetail === null ||
            getData?.subscriptionDetail !== null) && (
            <div className="form-view-reviews">
              <div id="reviews" className="container">
                <div className="row">
                  <div className="col-md-12 col-lg-6 col-xl-8">
                    {/* review content */}
                    {state?.auth?.user?.role === "host" &&
                    getData?.isClaimed === false &&
                    getData?.subscriptionDetail === null &&
                    state?.living?.reviewsForAnonymousUser.length > 0 ? (
                      <div className="custom-alert-message d-flex align-items-center justify-content-between flex-fill">
                        <p className="mb-0">
                          Reviews submitted are not visible until you claim your
                          facility and pay for the monthly subscription.
                        </p>
                        <a href="#" className="cancel-btn d-flex">
                          <img
                            src={canclePrimaryImg}
                            alt=""
                            onClick={(e) => {
                              e.preventDefault();
                              // sethidemodal(false);
                            }}
                          />
                        </a>
                      </div>
                    ) : (
                      ""
                    )}
                    {(state?.auth?.user?.role === "host" ||
                      state?.auth?.user?.role !== "host") && (
                      <div className="facility-content">
                        <div className="review-header d-flex align-items-center">
                          <h3 className="mt-0 mr-4 mb-4">Reviews</h3>
                          {state?.living?.reviewsForAnonymousUser.length !==
                            0 &&
                          isHost &&
                          getData?.subscriptionDetail === null ? (
                            ""
                          ) : (state?.living?.reviewsForAnonymousUser.length !==
                              0 &&
                              !isHost) ||
                            (isHost && getData?.subscriptionDetail != null) ? (
                            <></>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="reply-block">
                          {((getData?.isClaimed === false && !isHost) ||
                            !isHost) && (
                            <>
                              <div className="review-header d-flex align-items-center mb-4">
                                <h3 className="my-0 mr-4">Add Your Review</h3>
                                <>
                                  {state?.auth?.user?.role === "regular" &&
                                    hidemodal && (
                                      <div className="custom-alert-message d-flex align-items-center justify-content-between flex-fill">
                                        <p className="mb-0">
                                          Avoid sharing personal information.
                                          Taste Recovery is not liable for
                                          user-shared data
                                        </p>
                                        <a
                                          href="#"
                                          className="cancel-btn d-flex"
                                        >
                                          <img
                                            src={canclePrimaryImg}
                                            alt=""
                                            onClick={(e) => {
                                              e.preventDefault();
                                              sethidemodal(false);
                                            }}
                                          />
                                        </a>
                                      </div>
                                    )}
                                </>
                              </div>
                              <div className="form-group d-flex align-items-center">
                                <p>
                                  Rate Here{" "}
                                  <Rater
                                    total={5}
                                    rating={Number(rating)}
                                    onRating={({ rating }) => {
                                      checkLogin();
                                      setRating(rating);
                                    }}
                                  />
                                </p>
                              </div>
                            </>
                          )}
                          {rating && userName && !isHost && (
                            <>
                              <div className="form-group d-flex flex-column">
                                <label>Treated here from</label>
                                <input
                                  type="date"
                                  max={
                                    toDate
                                      ? toDate
                                      : new Date().toJSON().slice(0, 10)
                                  }
                                  className="form-control"
                                  value={fromDate}
                                  onChange={(e) => setFromDate(e.target.value)}
                                />
                              </div>
                              <div className="form-group d-flex flex-column">
                                <label>Treated here to</label>
                                <input
                                  type="date"
                                  min={fromDate}
                                  max={new Date().toJSON().slice(0, 10)}
                                  className="form-control"
                                  value={toDate}
                                  onChange={(e) => setToDate(e.target.value)}
                                />
                              </div>
                              <div className="form-group d-flex flex-column">
                                <label>Your review</label>
                                <textarea
                                  placeholder="Add your review here"
                                  name="review"
                                  maxlength="500"
                                  rows="4"
                                  className="form-control"
                                  value={comment}
                                  onChange={(e) => setComment(e.target.value)}
                                ></textarea>
                              </div>
                              <div className="form-group d-flex mu-15">
                                <button
                                  type="button"
                                  onClick={() => onSubmitReview()}
                                  disabled={!rating || comment.trim() === ""}
                                  className="btn btn-primary radius-20 justify-content-center mt-4 mb-4 mb-md-0"
                                >
                                  Submit
                                </button>
                              </div>
                            </>
                          )}
                        </div>

                        {(state?.living?.reviewsForAnonymousUser.length !== 0 &&
                          isHost &&
                          getData?.subscriptionDetail === null) ||
                        (getData?.subscriptionDetail === null &&
                          getData?.isClaimed === true) ? (
                          ""
                        ) : (state?.living?.reviewsForAnonymousUser.length !==
                            0 &&
                            !isHost &&
                            getData?.subscriptionDetail === null &&
                            state?.auth?.user?.id != undefined) ||
                          (state?.auth?.user?.id === undefined &&
                            getData?.subscriptionDetail === null) ||
                          (state?.living?.reviewsForAnonymousUser.length !==
                            0 &&
                            !isHost &&
                            state?.auth?.user?.id != undefined &&
                            getData?.subscriptionDetail === null) ? (
                          state?.living?.reviewsForAnonymousUser
                            ?.filter(
                              (ele) => ele?.user?.id === state?.auth?.user?.id
                            )
                            ?.map((ele) => {
                              const userNameInitial = ele?.user?.name
                                .charAt(0)
                                .toUpperCase();
                              return (
                                <>
                                  <div
                                    className="review-block d-flex"
                                    key={ele?.id || ele?.user?.id}
                                  >
                                    <div className="review-image">
                                      <Avatar
                                        as
                                        img
                                        name={ele?.user?.name
                                          ?.charAt(0)
                                          .toUpperCase()}
                                        size="40"
                                        className="img-fluid"
                                        round={true}
                                      />
                                    </div>
                                    <div className="review-detail flex-fill">
                                      <div className="top-info d-flex justify-content-between">
                                        <div className="reviewer-name-date">
                                          <h4 className="mb-1">
                                            {ele?.user?.name?.split(" ")[0]}
                                          </h4>
                                          <p>
                                            {" "}
                                            <Moment
                                              date={ele?.createdAt}
                                              format="DD MMM, YYYY"
                                            />
                                          </p>
                                        </div>
                                        <div className="star-rating d-flex align-items-center">
                                          <Rater
                                            rating={ele?.value}
                                            interactive={false}
                                          />
                                        </div>
                                      </div>
                                      <div className="bottom-info">
                                        {"livedFrom" in ele &&
                                          ele?.livedFrom !== null &&
                                          "livedTo" in ele &&
                                          ele?.livedTo !== null && (
                                            <p>
                                              Treated in this facility
                                              {"livedFrom" in ele &&
                                                ele?.livedFrom !== null && (
                                                  <>
                                                    {" "}
                                                    from{" "}
                                                    <Moment
                                                      date={ele?.livedFrom}
                                                      format="DD MMM, YYYY"
                                                    />
                                                  </>
                                                )}
                                              {"livedTo" in ele &&
                                                ele?.livedTo !== null && (
                                                  <>
                                                    {" "}
                                                    to{" "}
                                                    <Moment
                                                      date={ele?.livedTo}
                                                      format="DD MMM, YYYY"
                                                    />
                                                  </>
                                                )}
                                              .
                                            </p>
                                          )}

                                        <p>{ele?.comment}</p>

                                        {isHost &&
                                          state?.auth?.user?.id ==
                                            getData?.createdBy && (
                                            <div className="reply-button">
                                              <button
                                                type="button"
                                                className="btn-reply"
                                                onClick={() => {
                                                  setIsReply(true);
                                                  setReplyId(ele?.id);
                                                }}
                                              >
                                                <img
                                                  src={replyImg}
                                                  className="img-fluid"
                                                  alt=""
                                                />{" "}
                                                Reply
                                              </button>
                                            </div>
                                          )}
                                        {isHost &&
                                          isReply &&
                                          replyId == ele?.id && (
                                            <div className="reply-block mt-3">
                                              <div className="form-group d-flex flex-column">
                                                <textarea
                                                  placeholder="Reply to review"
                                                  name="review"
                                                  maxlength="500"
                                                  rows="4"
                                                  className="form-control"
                                                  onChange={(e) =>
                                                    setReplyReview({
                                                      content: e.target.value,
                                                    })
                                                  }
                                                ></textarea>
                                              </div>
                                              <div className="form-group d-flex mb-0">
                                                <button
                                                  disabled={
                                                    replyReview.content
                                                      .length == 0
                                                  }
                                                  type="button"
                                                  className="btn btn-primary radius-20 justify-content-center mt-4 mb-4 mb-md-0"
                                                  onClick={() =>
                                                    handleReplyReview(
                                                      replyReview,
                                                      ele?.id,
                                                      getData
                                                    )
                                                  }
                                                >
                                                  Submit
                                                </button>
                                              </div>
                                            </div>
                                          )}
                                      </div>
                                      {ele?.replies?.map((item) => {
                                        return (
                                          <div
                                            className="review-block review-reply-block d-flex"
                                            key={item?.id}
                                          >
                                            <div className="review-image">
                                              <Avatar
                                                as
                                                img
                                                name={state?.auth?.user?.name
                                                  ?.charAt(0)
                                                  .toUpperCase()}
                                                size="40"
                                                className="img-fluid"
                                                round={true}
                                              />
                                            </div>
                                            <div className="review-detail flex-fill">
                                              <div className="top-info d-flex justify-content-between">
                                                <div className="reviewer-name-date">
                                                  <h4 className="mb-1">
                                                    {
                                                      state?.auth?.user?.name?.split(
                                                        " "
                                                      )[0]
                                                    }
                                                  </h4>
                                                  <p>
                                                    {" "}
                                                    <Moment
                                                      date={item?.createdAt}
                                                      format="DD MMM, YYYY"
                                                    />
                                                  </p>
                                                </div>
                                                <div className="star-rating d-flex align-items-center"></div>
                                              </div>
                                              <div className="bottom-info">
                                                <p>{item?.content}</p>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </>
                              );
                            })
                        ) : state?.living?.reviewsForAnonymousUser.length !==
                            0 ||
                          (state?.living?.reviewsForAnonymousUser.length !==
                            0 &&
                            isHost) ? (
                          <>
                            {state?.living?.reviewsForAnonymousUser.length !==
                              0 &&
                              state?.living?.reviewsForAnonymousUser?.map(
                                (ele) => {
                                  const userNameInitial = ele?.user?.name
                                    .charAt(0)
                                    .toUpperCase();
                                  return (
                                    <>
                                      <div
                                        className="review-block d-flex"
                                        key={ele?.id || ele?.user?.id}
                                      >
                                        <div className="review-image">
                                          <Avatar
                                            as
                                            img
                                            name={ele?.user?.name
                                              ?.charAt(0)
                                              .toUpperCase()}
                                            size="40"
                                            className="img-fluid"
                                            round={true}
                                          />
                                        </div>
                                        <div className="review-detail flex-fill">
                                          <div className="top-info d-flex justify-content-between">
                                            <div className="reviewer-name-date">
                                              <h4 className="mb-1">
                                                {ele?.user?.name?.split(" ")[0]}
                                              </h4>
                                              <p>
                                                {" "}
                                                <Moment
                                                  date={ele?.createdAt}
                                                  format="DD MMM, YYYY"
                                                />
                                              </p>
                                            </div>
                                            <div className="star-rating d-flex align-items-center">
                                              <Rater
                                                rating={ele?.value}
                                                interactive={false}
                                              />
                                            </div>
                                          </div>
                                          <div className="bottom-info">
                                            {"livedFrom" in ele &&
                                              ele?.livedFrom !== null &&
                                              "livedTo" in ele &&
                                              ele?.livedTo !== null && (
                                                <p>
                                                  Treated in this facility
                                                  {"livedFrom" in ele &&
                                                    ele?.livedFrom !== null && (
                                                      <>
                                                        {" "}
                                                        from{" "}
                                                        <Moment
                                                          date={ele?.livedFrom}
                                                          format="DD MMM, YYYY"
                                                        />
                                                      </>
                                                    )}
                                                  {"livedTo" in ele &&
                                                    ele?.livedTo !== null && (
                                                      <>
                                                        {" "}
                                                        to{" "}
                                                        <Moment
                                                          date={ele?.livedTo}
                                                          format="DD MMM, YYYY"
                                                        />
                                                      </>
                                                    )}
                                                  .
                                                </p>
                                              )}

                                            <p>{ele?.comment}</p>

                                            {isHost &&
                                              state?.auth?.user?.id ==
                                                getData?.createdBy && (
                                                <div className="reply-button">
                                                  <button
                                                    type="button"
                                                    className="btn-reply"
                                                    onClick={() => {
                                                      setIsReply(true);
                                                      setReplyId(ele?.id);
                                                    }}
                                                  >
                                                    <img
                                                      src={replyImg}
                                                      className="img-fluid"
                                                      alt=""
                                                    />{" "}
                                                    Reply
                                                  </button>
                                                </div>
                                              )}
                                            {isHost &&
                                              isReply &&
                                              replyId == ele?.id && (
                                                <div className="reply-block mt-3">
                                                  <div className="form-group d-flex flex-column">
                                                    <textarea
                                                      placeholder="Reply to review"
                                                      name="review"
                                                      maxlength="500"
                                                      rows="4"
                                                      className="form-control"
                                                      onChange={(e) =>
                                                        setReplyReview({
                                                          content:
                                                            e.target.value,
                                                        })
                                                      }
                                                    ></textarea>
                                                  </div>
                                                  <div className="form-group d-flex mb-0">
                                                    <button
                                                      disabled={
                                                        replyReview.content
                                                          .length == 0
                                                      }
                                                      type="button"
                                                      className="btn btn-primary radius-20 justify-content-center mt-4 mb-4 mb-md-0"
                                                      onClick={() =>
                                                        handleReplyReview(
                                                          replyReview,
                                                          ele?.id,
                                                          getData
                                                        )
                                                      }
                                                    >
                                                      Submit
                                                    </button>
                                                  </div>
                                                </div>
                                              )}
                                          </div>
                                          {ele?.replies?.map((item) => {
                                            return (
                                              <div
                                                className="review-block review-reply-block d-flex"
                                                key={item?.id}
                                              >
                                                <div className="review-image">
                                                  <Avatar
                                                    as
                                                    img
                                                    name={state?.auth?.user?.name
                                                      ?.charAt(0)
                                                      .toUpperCase()}
                                                    size="40"
                                                    className="img-fluid"
                                                    round={true}
                                                  />
                                                </div>
                                                <div className="review-detail flex-fill">
                                                  <div className="top-info d-flex justify-content-between">
                                                    <div className="reviewer-name-date">
                                                      <h4 className="mb-1">
                                                        {
                                                          state?.auth?.user?.name?.split(
                                                            " "
                                                          )[0]
                                                        }
                                                      </h4>
                                                      <p>
                                                        {" "}
                                                        <Moment
                                                          date={item?.createdAt}
                                                          format="DD MMM, YYYY"
                                                        />
                                                      </p>
                                                    </div>
                                                    <div className="star-rating d-flex align-items-center"></div>
                                                  </div>
                                                  <div className="bottom-info">
                                                    <p>{item?.content}</p>
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          })}
                                        </div>
                                      </div>
                                    </>
                                  );
                                }
                              )}
                          </>
                        ) : (
                          ""
                        )}

                        {/* Review changes  */}

                        {/* {(isHost && getData?.subscriptionDetail === null) ||
                        state?.living?.reviewsForAnonymousUser.length == 0 ? (
                          <>
                            <div className="review-no-records position-relative text-center my-4 pb-2">
                              <div className="review-header d-flex align-items-center mb-4">
                                <h3 className="my-0 mr-4">Reviews</h3>
                              </div>
                              <img src={noReviewImg} className="mb-5 mt-4" />
                              <h2>OOPS, NO REVIEWS YET...</h2>
                              <p>Reviews will show up here</p>
                            </div>
                          </>
                        ) : (
                          ""
                        )} */}

                        {/* {(isHost && getData?.isClaimed === false) ||
                          (state?.living?.reviewsForAnonymousUser.length ===
                            0 && (
                            <div className="review-no-records position-relative text-center my-4 pb-2">
                              <div className="review-header d-flex align-items-center mb-4">
                                <h3 className="my-0 mr-4">Reviews</h3>
                              </div>
                              <img src={noReviewImg} className="mb-5 mt-4" />
                              <h2>OOPS, NO REVIEWS YET...</h2>
                              <p>Reviews will show up here</p>
                            </div>
                          ))} */}
                      </div>
                    )}

                    {
                      <div className="facility-content">
                        {(isHost && getData?.subscriptionDetail === null) ||
                        (state?.auth?.user?.id === undefined &&
                          getData?.subscriptionDetail === null) ||
                        (getData?.subscriptionDetail === null &&
                          getData?.isClaimed === true) ||
                        state?.living?.reviewsForAnonymousUser.length == 0 ? (
                          <>
                            <div className="review-no-records position-relative text-center my-4 pb-2">
                              <div className="review-header d-flex align-items-center mb-4">
                                {/* <h3 className="my-0 mr-4">Reviews</h3> */}
                              </div>
                              <img src={noReviewImg} className="mb-5 mt-4" />
                              <h2>OOPS, NO REVIEWS YET...</h2>
                              <p>Reviews will show up here</p>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    }
                  </div>

                  {recommendedFacilities?.length > 0 &&
                  getData?.subscriptionDetail ? (
                    <div className="col-md-7 col-lg-6 col-xl-4">
                      <RecommendedFacility
                        settings1={settings1}
                        recommendedSlider={recommendedSlider}
                        recommendedFacilities={recommendedFacilities}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          )}
        </div>

        <>
          {showmodel && (
            <div
              className={`modal fade photo-gallery-modal ${
                showmodel ? "show d-block" : ""
              }`}
              id="exampleModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="gallery-content">
                    <div className="modal-header border-0 p-0">
                      <div className="modal-header-top d-flex w-100 align-items-center">
                        <a role="button" className="d-none">
                          <img src={backImgBlack} alt="" />
                        </a>
                        <a
                          className="cancel-btn d-flex align-items-center"
                          role="button"
                          onClick={() => setshowmodel(false)}
                        >
                          <img
                            src={require("../../../assets/images/cancle.svg")}
                            className="img-fluid"
                            alt=""
                          />{" "}
                          <span>Close</span>
                        </a>
                        {/* <div className="pagination">
                          {currentSlide + 1} / {getData?.photos?.length}
                        </div> */}
                      </div>
                    </div>
                    <Slider {...gallerySlider} className="gallery-slider">
                      {getData?.preSalePlanType === "pro" ||
                      getData?.subscriptionDetail?.subscriptionType ===
                        "pro" ? (
                        getData?.photos?.map((obj, index) => {
                          return (
                            <>
                              <div className="slider-item" key={index}>
                                <img
                                  src={obj?.path}
                                  className="img-fluid"
                                  alt={getData?.name}
                                />
                                {/* <h3>{getData?.name} Room</h3> */}
                              </div>
                            </>
                          );
                        })
                      ) : (
                        <>
                          <div className="slider-item">
                            <img
                              src={getData?.photos[0].path}
                              className="img-fluid"
                              alt={getData?.name}
                            />
                            {/* <h3>{getData?.name} Room</h3> */}
                          </div>
                        </>
                      )}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      </section>
    </>
  );
};

export default ViewDetailsFacilityCenter;
