import { SERVER_APIS, STORAGE } from "../constants";
import { httpClient } from "../utils";

export const homeActionType = {
  Fetching_States: "[Fetching_States] Action",
  States_Fetched: "[States_Fetched] Action",
  Fetching_Living_By_Gender: "[Fetching_Living_By_Gender] Action",
  Living_By_Gender_Fetched: "[Living_By_Gender_Fetched] Action",
  Fetching_Living_By_Recommended: "[Fetching_Living_By_Recommended] Action",
  Living_By_Recommended_Fetched: "[Living_By_Recommended_Fetched] Action",
  Fetching_PlanState: "[Fetching_PlanState] Action",
  PlanState_Fetched: "[ PlanState_Fetched] Action",
  ModelState_Fetch: "[ModelState_Fetched] Action",
  Set_Coordinate: "[Set_Coordinate] Action",
  Search_InputField: "[Search_InputField] Action",
  Search_InputField_Coordinate: "[Search_InputFieldCoordinate] Action",
  Search_Coordinate: "[Search_Coordinate] Action",
  Fetching_Facility: "[Fetching_Facility] Action",
  Facility_Fetched_Filter: "[Fetching_Facility_Filter] Action",
  Facility_Fetched_All: "[Fetching_Facility_All] Action",
  Facility_Fetched: "[Facility_Fetched] Action",
  Fetching_Facility_RecentView: "[Fetching_Facility_RecentView] Action",
  Fetched_Facility_RecentView: "[Fetched_Facility_RecentView] Action",
  Fetching_Facility_ById: "[GetById_Facility] Action",
  Fetched_Facility_ById: "[GetById_Facility_Fetched] Action",
  Set_Param: "[Set_Param] Action",
  Fetching_Verified: "[Fetching_Verified] Action",
  Fetched_Verified: "[Fetched_Verified] Action",
  Fetched_All_Facility: "[Fetched_All_Facility] Action",
  UPLOAD_MEDIA: "[Upload_Media] Action",
  PPC_Auth: "[PPC_Clicked_Auth] Action",
  PPC_NonAuth: "[PPC_Clicked_NonAuth] Action",
};

const onFetchStatesAction = () => {
  return (dispatch) => {
    dispatch({ type: homeActionType.Fetching_States });
    return httpClient
      .get(SERVER_APIS.TOP_STATES_URL)
      .then((response) => {
        dispatch({
          type: homeActionType.States_Fetched,
          payload: { states: response.data },
        });
        return Promise.resolve();
      })
      .catch(() => {
        dispatch({
          type: homeActionType.States_Fetched,
          payload: { states: [] },
        });
        return Promise.reject();
      });
  };
};

const onFetchPlanAction = () => {
  return (dispatch) => {
    dispatch({ type: homeActionType.Fetching_PlanState });
    return httpClient
      .get(`${SERVER_APIS.Living_URL}/subscription/plans`, {
        headers: {
          Authorization: `Bearer ${
            STORAGE.AUTH_KEY ? STORAGE.AUTH_KEY : JSON.parse(localStorage.auth)
          }`,
        },
      })
      .then((response) => {
        dispatch({
          type: homeActionType.PlanState_Fetched,
          payload: { subscribePlan: response.data },
        });
        return Promise.resolve();
      })
      .catch(() => {
        dispatch({
          type: homeActionType.PlanState_Fetched,
          payload: { subscribePlan: [] },
        });
        return Promise.reject();
      });
  };
};

const onFetchLivingByGenderAction = (data) => {
  return (dispatch) => {
    dispatch({ type: homeActionType.Fetching_Living_By_Gender });
    return httpClient
      .post(SERVER_APIS.Living_TOP_BY_GENDER_URL, data)
      .then((response) => {
        dispatch({
          type: homeActionType.Living_By_Gender_Fetched,
          payload: { livingByGender: response.data },
        });
        return Promise.resolve();
      })
      .catch(() => {
        dispatch({
          type: homeActionType.Living_By_Gender_Fetched,
          payload: { livingByGender: [] },
        });
        return Promise.reject();
      });
  };
};

const onRecommended = (data) => {
  return (dispatch) => {
    dispatch({ type: homeActionType.Fetching_Living_By_Gender });
    return httpClient
      .post(SERVER_APIS.Living_Recommended, data)
      .then((response) => {
        dispatch({
          type: homeActionType.Living_By_Recommended_Fetched,
          payload: { livingByRecommended: response.data },
        });
        return Promise.resolve();
      })
      .catch(() => {
        dispatch({
          type: homeActionType.Living_By_Recommended_Fetched,
          payload: { livingByRecommended: [] },
        });
        return Promise.reject();
      });
  };
};

const setModelState = (data) => {
  return (dispatch) => {
    dispatch({
      type: homeActionType.ModelState_Fetch,
      payload: data,
    });
  };
};

const onFetchFacilityListAction = (data) => {
  return (dispatch) => {
    dispatch({ type: homeActionType.Fetching_Facility });
    return httpClient
      .post(SERVER_APIS.Facility_List, data)
      .then((response) => {
        dispatch({
          type: homeActionType.Facility_Fetched,
          payload: response.data,
        });
        return Promise.resolve(response?.data);
      })
      .catch(() => {
        dispatch({
          type: homeActionType.Fetching_Facility,
          payload: [],
        });
        return Promise.reject();
      });
  };
};

const fetchFacilityListForFilter = (data) => {
  return (dispatch) => {
    dispatch({ type: homeActionType.Fetching_Facility });
    return httpClient
      .post(SERVER_APIS.Facility_List, data)
      .then((response) => {
        dispatch({
          type: homeActionType.Facility_Fetched_Filter,
          payload: response.data,
        });
        return Promise.resolve(response?.data);
      })
      .catch(() => {
        dispatch({
          type: homeActionType.Fetching_Facility,
          payload: [],
        });
        return Promise.reject();
      });
  };
};

const fetchFacilityListwithoutCords = (data) => {
  return (dispatch) => {
    dispatch({ type: homeActionType.Fetching_Facility });
    return httpClient
      .post(SERVER_APIS.Facility_List, data)
      .then((response) => {
        dispatch({
          type: homeActionType.Facility_Fetched_All,
          payload: response.data,
        });
        return Promise.resolve(response?.data);
      })
      .catch(() => {
        dispatch({
          type: homeActionType.Fetching_Facility,
          payload: [],
        });
        return Promise.reject();
      });
  };
};

const getFacilityDataById = (id, history) => {
  return async (dispatch) => {
    try {
      dispatch({ type: homeActionType.Fetching_Facility_ById });
      const res = await httpClient.get(`${SERVER_APIS.Facility_By_Id}/${id}`);
      dispatch({
        type: homeActionType.Fetched_Facility_ById,
        payload: res.data,
      });
      return res.data;
    } catch (error) {
      if (error.response.data?.data[0] === "Facility is deleted") {
        history.push(-1);
      }
      dispatch({
        type: homeActionType.Fetching_Facility_ById,
        payload: { error },
      });
      throw error;
    }
  };
};

const getFacilityRecentView = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: homeActionType.Fetching_Facility_RecentView });
      const res = await httpClient.put(
        `${SERVER_APIS.Facility_By_Id}/${id}/viewed`
      );
      dispatch({
        type: homeActionType.Fetched_Facility_RecentView,
        payload: res,
      });
      return res;
    } catch (error) {
      console.error("Error occurred:", error);
      dispatch({
        type: homeActionType.Fetching_Facility_RecentView,
        payload: { error },
      });
      throw error;
    }
  };
};

const getFacilityDataByIdWithoutLogin = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: homeActionType.Fetching_Facility_ById });
      const res = await httpClient.get(
        `${SERVER_APIS.Facility_By_Id}/${id}/home`
      );
      dispatch({
        type: homeActionType.Fetched_Facility_ById,
        payload: res.data,
      });
      return res.data;
    } catch (error) {
      console.error("Error occurred:", error);
      dispatch({
        type: homeActionType.Fetching_Facility_ById,
        payload: { error },
      });
      throw error;
    }
  };
};

const verifiedBadgeRequest = (data) => {
  return (dispatch) => {
    dispatch({ type: homeActionType.Fetching_Verified });
    return httpClient
      .post(SERVER_APIS.Verified_Badge, data)
      .then((response) => {
        dispatch({
          type: homeActionType.Fetched_Verified,
          payload: response.data,
        });
        return Promise.resolve(response?.data);
      })
      .catch(() => {
        dispatch({
          type: homeActionType.Fetching_Verified,
          payload: [],
        });
        return Promise.reject();
      });
  };
};

const ppcClickBySignInUser = (data) => {
  return (dispatch) => {
    return httpClient
      .post(SERVER_APIS.PPC_Auth, data)
      .then((response) => {
        dispatch({
          type: homeActionType.PPC_Auth,
          payload: data,
        });
        return Promise.resolve();
      })
      .catch(() => {
        dispatch({
          type: homeActionType.PPC_Auth,
          payload: {},
        });
        return Promise.reject();
      });
  };
};

const ppcClickNonAuth = (data) => {
  return (dispatch) => {
    return httpClient
      .post(SERVER_APIS.PPC_Non_Auth, data)
      .then((response) => {
        dispatch({
          type: homeActionType.PPC_NonAuth,
          payload: data,
        });
        return Promise.resolve();
      })
      .catch(() => {
        dispatch({
          type: homeActionType.PPC_NonAuth,
          payload: {},
        });
        return Promise.reject();
      });
  };
};

const setCordinates = (data) => {
  return (dispatch) => {
    dispatch({
      type: homeActionType.Set_Coordinate,
      payload: data,
    });
  };
};

const setSearchInputField = (data) => {
  return (dispatch) => {
    dispatch({
      type: homeActionType.Search_InputField,
      payload: data,
    });
  };
};

const setSearchCoordinateName = (data) => {
  return (dispatch) => {
    dispatch({
      type: homeActionType.Search_InputField_Coordinate,
      payload: data,
    });
  };
};

const setSearchCoodinates = (data) => {
  return (dispatch) => {
    dispatch({
      type: homeActionType.Search_Coordinate,
      payload: data,
    });
  };
};

const setParam = (data) => {
  return (dispatch) => {
    dispatch({
      type: homeActionType.Set_Param,
      payload: data,
    });
  };
};

const getAllFacilityList = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: homeActionType.Fetching_Facility_ById });
      const res = await httpClient.get(`${SERVER_APIS.All_Facility_List}`);
      dispatch({
        type: homeActionType.Fetched_All_Facility,
        payload: res.data,
      });
      return res.data;
    } catch (error) {
      console.error("Error occurred:", error);
      dispatch({
        type: homeActionType.Fetching_Facility_ById,
        payload: { error },
      });
      throw error;
    }
  };
};

export const homeAction = {
  fetchStates: onFetchStatesAction,
  fetchLivingByGender: onFetchLivingByGenderAction,
  fetchLivingByRecommended: onRecommended,
  fetchPlan: onFetchPlanAction,
  setModelState,
  fetchFacilityList: onFetchFacilityListAction,
  getFacilityDataById,
  verifiedBadgeRequest,
  ppcClickBySignInUser,
  ppcClickNonAuth,
  getFacilityDataByIdWithoutLogin: getFacilityDataByIdWithoutLogin,
  getFacilityRecentViewDetail: getFacilityRecentView,
  setCordinates: setCordinates,
  setSearchInputField,
  setSearchCoodinates,
  setSearchCoordinateName,
  setParam,
  fetchFacilityListwithoutCords,
  fetchFacilityListForFilter,
  getAllFacilityList,
};
